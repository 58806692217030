import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withAdvancesSummary(Component) {
	return (props) => {

		const advancesSummary = useSelector(state => state.myDashboard.advancesSummary)
		const loadingStatus = useSelector(state => state.myDashboard.advancesSummaryStatus  )
		const dispatch = useDispatch()
		const advancesSummarySDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			advancesSummary,
      loadingStatus
		}
		const passThruProps = {...props, advancesSummarySDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withAdvancesSummary