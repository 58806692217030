import React from 'react';
import {createSvgIcon} from '@mui/material';

const Logout = createSvgIcon(
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.4">
			<path d="M17.4395 14.62L19.9995 12.06L17.4395 9.5" stroke="#546A7B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.75977 12.0596H19.9298" stroke="#546A7B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<path d="M11.76 20C7.34001 20 3.76001 17 3.76001 12C3.76001 7 7.34001 4 11.76 4" stroke="#546A7B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
	,
	'Logout'
)

export default Logout