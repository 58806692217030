import styled from '@mui/material/styles/styled'


const StyledPageHeader = styled('div')(({ theme }) => ({ 
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '6px 24px 26px 24px',
  position: 'sticky',
  top: '70px',
  marginLeft: '-16px',
  zIndex: 2,
  width: 'calc(100% + 32px)',
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
  '& > *': {
    flexGrow: 1
  },
  [theme.breakpoints.up('md')]: {
    top: 0
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '-24px',
    width: 'calc(100% + 48px)',
    top: 0
  }
}))

const Content = styled('div')(({ theme }) => ({ 
  maxWidth: '50%',
}))

const PageHeader = props => {
  return (
    <StyledPageHeader>
      <Content>
        {props.children}
      </Content>
    </StyledPageHeader>
  )
}

export default PageHeader