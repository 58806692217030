const constants = {
  FIND_ALL_EMPLOYEES: 'my-employees/FIND_ALL_EMPLOYEES',
  FIND_EMPLOYEES_PAGE:'my-employees/FIND_EMPLOYEES_PAGE',
  FIND_ONE_EMPLOYEE: 'my-employees/FIND_ONE_EMPLOYEE',
  RESET_ALL_EMPLOYEES: 'my-employees/RESET_ALL_EMPLOYEES',
  FIND_EMPLOYEE_ADVANCES: 'my-employees/FIND_EMPLOYEE_ADVANCES',
  RESET_EMPLOYEE: 'my-employees/RESET_EMPLOYEE',
  RESET_EMPLOYEE_ADVANCES: 'my-employees/RESET_EMPLOYEE_ADVANCES',
  FIND_EMPLOYEE_SAFEGUARDS: 'my-employees/FIND_EMPLOYEE_SAFEGUARDS',
  CREATE_EMPLOYEE: 'my-employees/CREATE_EMPLOYEE',
  RESET_CREATED_EMPLOYEE: 'my-employees/RESET_CREATE_EMPLOYEE',
  RESET_EMPLOYEE_SAFEGUARDS: 'my-employees/RESET_EMPLOYEE_SAFEGUARDS',
  PATCH_EMPLOYEE_PENDING_TERMINATION: 'my-employees/PATCH_EMPLOYEE_PENDING_TERMINATION',
  PATCH_EMPLOYEE_STATUS: 'my-employees/PATCH_EMPLOYEE_STATUS',
  UPDATE_EMPLOYEE: 'my-employees/UPDATE_EMPLOYEE',
  UPDATE_EMPLOYEE_SAFEGUARDS: 'my-employees/UPDATE_EMPLOYEE_SAFEGUARDS',
  FIND_MY_COMPANY_RULESET: 'my-employees/FIND_MY_COMPANY_RULESET',
  GHOSTING_FIND_EMPLOYEE_EOB_HOURLY: 'my-employees/GHOSTING_FIND_EMPLOYEE_EOB_HOURLY',
  GHOSTING_FIND_EMPLOYEE_EOB_SALARY: 'my-employees/GHOSTING_FIND_EMPLOYEE_EOB_SALARY',
  GHOSTING_RESET_EOB: 'my-employees/GHOSTING_RESET_EOB',
  GHOSTING_ESCALATE_TO_TIER_3: 'my-employees/GHOSTING_ESCALATE_TO_TIER_3',
  GHOSTING_ESCALATE_TO_TIER_3_RESET: 'my-employees/GHOSTING_ESCALATE_TO_TIER_3_RESET',
  FIND_EMPLOYEE_MFA_METHODS: 'my-employees/FIND_EMPLOYEE_MFA_METHODS',
  REMOVE_AUTH0_MFA_METHOD: 'my-employees/REMOVE_AUTH0_MFA_METHOD',
  RESET_EMPLOYEE_MFA_METHODS: 'my-employees/RESET_EMPLOYEE_MFA_METHODS',
  CREATE_EWA_EMPLOYEE_OTP: 'my-employees/CREATE_EWA_EMPLOYEE_OTP',
  SEND_EWA_EMPLOYEE_WELCOME_EMAIL: 'my-employees/SEND_EWA_EMPLOYEE_WELCOME_EMAIL',
  CREATE_INTERNAL_NOTES: 'my-employees/CREATE_INTERNAL_NOTES',
  GET_LATEST_INTERNAL_NOTES: 'my-employees/GET_LATEST_INTERNAL_NOTES',
  RESET_INTERNAL_NOTES: 'my-employees/RESET_INTERNAL_NOTES',
}
export default constants
