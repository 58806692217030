import React from 'react';
import {createSvgIcon} from '@mui/material';

const Cloud = createSvgIcon(
  <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6667 22.2201C20.5577 22.2201 25.3333 17.6788 25.3333 12.0769C25.3333 6.47489 20.5577 1.93359 14.6667 1.93359C8.77563 1.93359 4 6.47489 4 12.0769C4 17.6788 8.77563 22.2201 14.6667 22.2201Z" fill="#C0D9DE"/>
    <path d="M20.27 18.6801C19.28 19.5901 17.98 20.0901 16.63 20.0801H5.54C0.870003 19.7401 0.860003 12.9401 5.54 12.6001H5.59C2.89 5.06014 12.12 1.34014 16 6.03014V6.04014C16.7 6.90014 17.23 8.04014 17.47 9.49014C18.8 9.66014 19.88 10.3101 20.65 11.2301C22.4 13.3001 22.55 16.6801 20.27 18.6801Z" stroke="#546A7B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.26 13.0101C6.74 12.7501 6.17 12.6101 5.59 12.6001" stroke="#546A7B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.85 9.91977C16.37 9.65977 16.94 9.51977 17.52 9.50977" stroke="#546A7B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.97 8.5C21.97 9.6 21.46 10.59 20.65 11.23C19.88 10.31 18.8 9.66 17.47 9.49C17.23 8.04 16.7 6.9 16 6.04V6.03C16.63 5.39 17.51 5 18.47 5C20.4 5 21.97 6.57 21.97 8.5Z" stroke="#546A7B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
	,
	'Cloud'
)

export default Cloud