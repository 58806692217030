import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';



const authProviderConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  scope: 'openid profile email portal:admin:*',
  authorizationParams: {
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  }
}

const Auth0 = ({children}) => {
  return (
    <Auth0Provider
      {...authProviderConfig}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0