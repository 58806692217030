import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withSupportEscalation(Component) {
	return (props) => {

		const escalationStatus = useSelector(state => state.myEmployees.supportEscalationStatus)
		
		const dispatch = useDispatch()
		const escalationSupportSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			escalationStatus
		}
		const passThruProps = {...props, escalationSupportSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withSupportEscalation