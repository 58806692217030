const select = {
	MuiSelect: {
		styleOverrides: {
			root: ({ theme }) => ({
				minWidth: '120px',
				fontSize: '16px',
				lineHeight: '14',
				height: '56px',
				'&:hover': {
					'& fieldset.MuiOutlinedInput-notchedOutline': {
						borderWidth: '2px',
						borderColor: theme.palette.primary.main,
					}
				},
				'& fieldset.MuiOutlinedInput-notchedOutline': {
					// borderColor: theme.palette.primary.main,
					borderWidth: '1px',
					borderRadius: '10px',
				}
			}),
			select: ({ theme }) => ({
				color: theme.palette.primary.main
			}),
			icon: ({ theme }) => ({
				fontSize: '2.5rem',
				color: theme.palette.primary.main,
				right: 0
			})
		}
	},
}

export default select