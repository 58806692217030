import React from "react";
import styled from "@mui/material/styles/styled";
import { Box, Paper, Typography, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import withExplanationOfBenefits from "../../sdk/withEmployeeEOB";
import { useLocation } from "react-router-dom";
import EscalationForm from "./escalation-form";
import Modal from "@mui/material/Modal";

const StyledModalBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: "16px"
}));

const StyledHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-staft",
  justifyContent: "flex-start",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "150px",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: "16px",
}));

const StyledContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

const StyledPersonalInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "stretch",
  height: "100%",
  // padding: "16px",
}));

const StyledEligibility = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "stretch",
  height: "100%",
  // padding: "16px",
}));

const EmptyBox = styled(Box)(({ theme }) => ({
  width: 0,
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

const StyledInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  alignItems: "flex-start",
  height: "100%",
  padding: "16px",
  width: "100%",
  marginBottom: "8px",
  borderBottom: "1px solid #e0e0e0",
}));

const StyledSalaryBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // justifyContent: "stretch",
  alignItems: "space-around",
  height: "100%",
  padding: "16px",
  width: "100%",
  marginBottom: "8px",
  borderBottom: "1px solid #e0e0e0",
  [theme.breakpoints.up("sm")]: {
    borderBottom: "none",
  }
}));

const StyledEligibilityBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    borderLeft: "1px solid #e0e0e0",
    paddingLeft: "32px",
    marginLeft: "32px",
  },
}));

const ShiftContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // padding: "16px",
  marginBottom: "16px",
}));

const SectionHeader = styled("div")(({ theme }) => ({
  marginBottom: "16px",
}));

const InfoView = (header, data) => (
  <StyledInfoBox>
    <Typography variant="h7" color="primary">
      {header}
    </Typography>
    <Typography>{data}</Typography>
  </StyledInfoBox>
);

const SalaryView = (header, data) => (
  <StyledSalaryBox>
    <Typography variant="h7" color="primary">
      {header}
    </Typography>
    <Typography>{data}</Typography>
  </StyledSalaryBox>
);

const ExplanationOfBenefits = ({ employeeEOBSDK }) => {
  const { pathname } = useLocation();
  const [id] = pathname.split("/").reverse();
  const [escalate, setEscalate] = React.useState(false);

  const {
    getHourlyEOB,
    getSalaryEOB,
    explanationOfBenefits,
    employee,
    resetEOB,
  } = employeeEOBSDK;
  const { employmentType } = employee;
  const {
    shifts,
    rate,
    personalSafeguard,
    eligibility: { netAmount, reason },
    grossEligibility,
    advancesTaken,
    advancingStructure
  } = explanationOfBenefits;

  const {
    workDaysInYear,
    eligibleDaysInPayPeriod,
    dailyRate,
  } = advancingStructure;

  React.useEffect(() => {
    if (employmentType === "hourly") getHourlyEOB(id);
    else if (employmentType === "salary") getSalaryEOB(id);
  }, [employmentType]);

  React.useEffect(() => {
    return resetEOB;
  }, []);

  return (
    <Box>
      <StyledHeader>
        <Typography variant="h6" sx={{ marginBottom: "16px" }} color="primary">
          Explanation of Benefits
        </Typography>
      </StyledHeader>
      <Paper sx={{ padding: "16px" }}>
        {employmentType === "hourly" && (
          <ShiftContainer>
            <SectionHeader>
              <Typography variant="h6" color="primary">
                Current Shifts
              </Typography>
            </SectionHeader>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 335 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <StyledTableCell align="right">
                      Recorded Hrs
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Eligible Hrs
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(shifts.length ? shifts : [{ date: "No Shifts" }]).sort((a,b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA;
                  }).map(
                    (row) => (
                      <TableRow
                        key={row.display_date + row.hours + row.adjusted_hours}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.display_date}
                        </TableCell>
                        <StyledTableCell align="right">
                          {row.hours}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.adjusted_hours}
                        </StyledTableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </ShiftContainer>
        )}
        {employmentType === "salary" && (
          <>
          <SectionHeader>
            <Typography variant="h6" color="primary">
              Salary Structure
            </Typography>
          </SectionHeader>
          <StyledCard>
            <StyledContent>
                {SalaryView("Work Days In Year", workDaysInYear)}
                {SalaryView("Eligibile Days In Pay Period", eligibleDaysInPayPeriod)}
                {SalaryView("Daily Rate", `$${dailyRate}/day`)}
            </StyledContent>
          </StyledCard>
          </>
        )}
        <SectionHeader>
          <Typography variant="h6" color="primary">
            Eligibility Breakdown
          </Typography>
        </SectionHeader>
        <StyledCard>
          <StyledContent>
            <StyledPersonalInfo>
              {InfoView(employmentType === 'hourly' ? "Hourly Rate" : "Salary", `$${rate}${employmentType === 'hourly' ? "/hr" : "/year"}`)}
              {InfoView("Personal Safeguard", `$${personalSafeguard}`)}
            </StyledPersonalInfo>
            <StyledEligibility>
              <StyledEligibilityBox>
                {InfoView(
                  `Net Eligibility: $${netAmount}`,
                  `Limiting Reason: ${reason}`
                )}
                {InfoView(
                  "Gross Eligibility - Advances",
                  `$${grossEligibility} - $${advancesTaken} = $${grossEligibility - advancesTaken}`
                )}
              </StyledEligibilityBox>
            </StyledEligibility>
            <EmptyBox />
          </StyledContent>
          <StyledCardActions>
            <Button onClick={() => setEscalate(true)} size="large">
              Escalate to Tier 3
            </Button>
          </StyledCardActions>
        </StyledCard>
      </Paper>
      <Modal open={escalate} onClose={() => setEscalate(false)}>
        <StyledModalBox>
          <EscalationForm onCancel={() => setEscalate(false)} setEscalate={setEscalate} />
        </StyledModalBox>
      </Modal>
    </Box>
  );
};

export default withExplanationOfBenefits(ExplanationOfBenefits);
