import { createAsyncThunk } from '@reduxjs/toolkit'
import constants from '../redux/constants'

const api = client => ({
    createAdmin: (admin) => {
        return async dispatch => {
            const createAdminUsers = createAsyncThunk(
                constants.CREATE_ADMIN,
                async (_, thunkAPI) => {
                    try {
                        const response = await client.post(`/api/v2/my-company-settings/admins`, admin)
                        return response.data
                    } catch(e) {
                        return thunkAPI.rejectWithValue({
                            data: e.response?.data,
                            status: e.response?.status
                        })
                    }
                }
            )
            dispatch(createAdminUsers())
        }
    },
    getAllAdmins: () => {
        return async dispatch => {
            const getAdminUsers = createAsyncThunk(
                constants.GET_ALL_ADMINS,
                async (_, thunkAPI) => {
                    try {
                        const response = await client.get(`/api/v2/my-company-settings/admins`)
                        return response.data
                    } catch(e) {
                        return thunkAPI.rejectWithValue({
                            data: e.response?.data,
                            status: e.response?.status
                        })
                    }
                }
            )
            dispatch(getAdminUsers())
        }
    },
    resetAllAdmins: () => {
        return async dispatch => {
            dispatch({ type: constants.RESET_ALL_ADMINS })
        }
    },


    deleteAdmin: (user_id) => {
        return async dispatch => {
            const deleteAdminUser = createAsyncThunk(
                constants.DELETE_ADMIN,
                async (_, thunkAPI) => {
                    try {
                        const response = await client.delete(`/api/v2/my-company-settings/admins/${user_id}`)
                        return response.data
                    } catch(e) {
                        return thunkAPI.rejectWithValue({
                            data: e.response?.data,
                            status: e.response?.status
                        })
                    }
                }
            )
            dispatch(deleteAdminUser())
        }
    },
    resetDeleteAdmin: () => {
        return async dispatch => {
            dispatch({ type: constants.RESET_DELETE_ADMIN })
        }
    },

    createEmailRecipient: (email) => {
        return async dispatch => {
            const createRecipient = createAsyncThunk(
                constants.CREATE_EMAIL_RECIPIENT,
                async (_, thunkAPI) => {
                    try {
                        const response = await client.post(`/api/v2/my-company-settings/email-recipients`, { email })
                        return response.data
                    } catch(e) {
                        return thunkAPI.rejectWithValue({
                            data: e.response?.data,
                            status: e.response?.status
                        })
                    }
                }
            )
            dispatch(createRecipient())
        }
    },
    resetCreateEmailRecipient: () => {
        return async dispatch => {
            dispatch({ type: constants.RESET_CREATE_EMAIL_RECIPIENT })
        }
    },
    getEmailRecipients: () => {
        return async dispatch => {
            const getRecipients = createAsyncThunk(
                constants.GET_EMAIL_RECIPIENTS,
                async (_, thunkAPI) => {
                    try {
                        const response = await client.get(`/api/v2/my-company-settings/email-recipients`)
                        return response.data
                    } catch(e) {
                        return thunkAPI.rejectWithValue({
                            data: e.response?.data,
                            status: e.response?.status
                        })
                    }
                }
            )
            dispatch(getRecipients())
        }
    },
    deleteEmailRecipient: (id) => {
        return async dispatch => {
            const deleteRecipient = createAsyncThunk(
                constants.DELETE_EMAIL_RECIPIENT,
                async (_, thunkAPI) => {
                    try {
                        const response = await client.delete(`/api/v2/my-company-settings/email-recipients/${id}`)
                        return response.data
                    } catch(e) {
                        return thunkAPI.rejectWithValue({
                            data: e.response?.data,
                            status: e.response?.status
                        })
                    }
                }
            )
            dispatch(deleteRecipient())
        }
    },
    resetDeleteEmailRecipient: () => {
        return async dispatch => {
            dispatch({ type: constants.RESET_DELETE_EMAIL_RECIPIENT })
        }
    },
    setPrimaryEmailRecipient: (id) => {
        return async dispatch => {
            const deleteRecipient = createAsyncThunk(
                constants.SET_PRIMARY_EMAIL_RECIPIENT,
                async (_, thunkAPI) => {
                    try {
                        const response = await client.put(`/api/v2/my-company-settings/email-recipients/${id}/primary`)
                        return response.data
                    } catch(e) {
                        return thunkAPI.rejectWithValue({
                            data: e.response?.data,
                            status: e.response?.status
                        })
                    }
                }
            )
            dispatch(deleteRecipient())
        }
    },
    resetPrimaryEmailRecipient: () => {
        return async dispatch => {
            dispatch({ type: constants.RESET_SET_PRIMARY_EMAIL_RECIPIENT })
        }
    },
})

export default api