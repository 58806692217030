import React from "react";
import { 
  Typography, 
  TextField, 
  Paper,
  Button,
  ButtonGroup,
  InputAdornment,
  FormControl,
  InputLabel,
  NativeSelect,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import styled from '@mui/material/styles/styled'
import withEmployee from '../../sdk/withEmployee'
import { useLocation } from 'react-router-dom'
import Skeletonized from "./skeletonized";
import InfoNotification from '../../../../components/notification';
import SaveSnackbar from "../../../../components/save-snackbar";


const StyledRow = styled('div')(({ theme }) => ({
  justifyContent: 'flex-start',
  display: 'flex',
  width: '100%',
  padding: '16px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '16px 0',
  }
}))

const StyledField = styled(TextField)(({ theme }) => ({
  margin: '0 32px 12px 0',
}))

const StyledFieldCapitalized = styled(TextField)(({ theme }) => ({
  margin: '0 32px 12px 0',
  '& input': {
    textTransform: 'capitalize'
  }
}))

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: "16px 0"
}))

const StyledHeader = styled('div')(({ theme }) => ({ 
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: '0 32px 12px 0',
}))

const StyledPendingLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: '0 32px 24px 0',
  '& > span': {
    color: '#546A7B'
  }
}))

const EmployeeDetails = props => {
  const location = useLocation()
  const [ id ] = location.pathname.split('/').reverse()
  
  const { 
    employeeSDK: { 
      employee, 
      findOne, 
      loadingStatus,
      updateEmployee, 
      updateLoadingStatus,
      togglePendingTermination,  
      resetEmployee,
    },
  } = props
  const placeholderPhones = ['18005550100']
  const [ showSnackbar, setShowSnackbar ] = React.useState({show: false, severity: 'info', message: ''})
  const [ firstSave, setFirstSave ] = React.useState(false)
  const [ empType, setEmpType ] = React.useState({})
  const [ wageError, setWageError ] = React.useState(false)
  const [ pendingTerminationChecked, setPendingTermination ] = React.useState(employee.pendingTermination)

  const formRef = React.useRef(null)
  const firstNameRef = React.useRef(null)
  const lastNameRef = React.useRef(null)
  const externalIdRef = React.useRef(null)
  const emailRef = React.useRef(null)
  const phoneRef = React.useRef(null)
  const positionRef = React.useRef(null)
  const compensationTypeRef = React.useRef(null)
  const wageRef = React.useRef(null)
  const payrollIdRef = React.useRef(null)

  React.useEffect(() => {
    return () => resetEmployee()
  }, [])

  React.useEffect(() => {
    if(firstSave && updateLoadingStatus === 'fulfilled'){
      setShowSnackbar({show: true, severity: 'success'})
    } else if(updateLoadingStatus === 'rejected') {
      setShowSnackbar({show: true, severity: 'error'})
    } else {
      setShowSnackbar({show: false, severity: 'success'})
    }
  }, [updateLoadingStatus])

  React.useEffect(() => {
    findOne(id)
  }, [])

  React.useEffect(() => {
    setPendingTermination(employee.pendingTermination)
  }, [employee.pendingTermination])

  React.useEffect(() => {
    const type = employee.employmentType
    setEmpType({type, rate: type === 'hourly' ? employee.hourlyWage : employee.salary})    
  },[employee.employmentType])

  if(loadingStatus === 'idle') return (<Skeletonized />)
  else if(loadingStatus === 'pending') return (<Skeletonized />)

  const checkForNumbers = (e) => {
    const { value } = e.target
    const isAllNumbers =  /^\d+(\.\d{1,2})?$/.test(value)
    setWageError(!isAllNumbers)
    setEmpType({...empType, rate: value})
  }

  const {
    firstName,
    lastName,
    email,
    phone,
    externalEmployeeId,
    position,
    controlType,
    payrollId
  } = employee
  
  const makePayload = (e) => ({
    first_name: firstNameRef.current.querySelector('input').value,
    last_name: lastNameRef.current.querySelector('input').value,
    external_employee_id: externalIdRef.current.querySelector('input').value,
    email: emailRef.current.querySelector('input').value,
    phone: phoneRef.current.querySelector('input').value,
    position: positionRef.current.querySelector('input').value,
    employment_type: compensationTypeRef.current.querySelector('select').value,
    payroll_id: payrollIdRef.current.querySelector('input').value,
    ...getCompensations(compensationTypeRef.current.querySelector('select').value, wageRef.current.querySelector('input').value)
  })

  const getCompensations = (type, value) => {
    if (type === 'hourly') {
      return {hourly_wage: value, salary: 0}
    } else if(type === 'salary') {
      return {hourly_wage: 0, salary: value}
    } else return { hourly_wage: 0, salary: 0 }
  }
  
  const onUpdateEmployee = (e) => {
    e.preventDefault()
    const payload = makePayload(e)
    updateEmployee(id, payload)
    setFirstSave(true)
  }

  const updateWageField = (e) => {
    const value = e.target.value
    setEmpType({type: value, rate: value === 'hourly' ? employee.hourlyWage : employee.salary})
  }

  const onChangePendingTermination = (e) => {
    setPendingTermination(e.target.checked)
    togglePendingTermination(id, e.target.checked)
  }
  
  const isROGhost = sessionStorage.getItem('ghosting-role') === 'read-only'
  const isAutomated = controlType === 'automated'
  let displayPhone = placeholderPhones.includes(phone) ? '' : phone
  return (
      <StyledForm
        onSubmit={onUpdateEmployee}
        ref={formRef}
      >
        <StyledHeader>
          <Typography sx={{marginBottom: '16px'}} variant="h6" color='primary'>Employee Information</Typography>
          {controlType === 'automated' && <InfoNotification message={'Employee is under automated control and cannot be edited here. To make changes, update the directory export you provide us.'} />}
        </StyledHeader>
        <Paper sx={{padding: '16px'}}>
          <StyledRow>
            <StyledFieldCapitalized 
              fullWidth
              required
              label="First Name"
              defaultValue={firstName}
              variant='standard'
              ref={firstNameRef}
              InputProps={{
                readOnly: controlType === 'automated'
              }}
            />
            <StyledFieldCapitalized
              fullWidth
              required
              label="Last Name"
              defaultValue={lastName}
              variant='standard'
              ref={lastNameRef}
              InputProps={{
                readOnly: controlType === 'automated'
              }}
            />
          </StyledRow>
          <StyledRow>
          <StyledField
              fullWidth
              required
              label="Email"
              defaultValue={email}
              ref={emailRef}
              variant='standard'
              type='email'
              InputProps={{
                readOnly: true,
              }}
            />
            <StyledField
              fullWidth
              label="External ID"
              defaultValue={externalEmployeeId}
              variant='standard'
              required
              ref={externalIdRef}
              InputProps={{
                readOnly: controlType === 'automated'
              }}
            />
            <StyledField
              fullWidth
              label="Payroll ID"
              defaultValue={payrollId}
              variant='standard'
              ref={payrollIdRef}
              InputProps={{
                readOnly: controlType === 'automated'
              }}
            />
            <StyledField
              fullWidth
              label="Contact Phone"
              defaultValue={displayPhone}
              variant='standard'
              ref={phoneRef}
              type='tel'
              InputProps={{
                readOnly: controlType === 'automated'
              }}
            />
          </StyledRow>
          <StyledRow>
            <StyledFieldCapitalized
              fullWidth
              label="Position"
              defaultValue={position}
              variant='standard'
              required
              ref={positionRef}
              InputProps={{
                readOnly: controlType === 'automated'
              }}
            />
            <StyledFormControl  fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Compensation Type
              </InputLabel>
              <NativeSelect
                onChange={updateWageField}
                ref={compensationTypeRef}
                disabled={controlType === 'automated'}
                value={empType.type}
                inputProps={{
                  name: 'compensationTpye',
                  id: 'uncontrolled-native',
                }}
              >
                <option value={'hourly'}>Hourly</option>
                <option value={'salary'}>Salary</option>
              </NativeSelect>
            </StyledFormControl>
            <StyledField
              fullWidth
              required
              ref={wageRef}
              label={empType.type === 'hourly' ? 'Hourly' : 'Salary'}
              value={empType.rate}
              variant='standard'
              error={wageError}
              onChange={checkForNumbers}
              helperText={wageError ? 'Must be a currency amount' : ''}
              InputProps={{
                readOnly: controlType === 'automated',
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              
            />
          </StyledRow>
         
          {isAutomated && !isROGhost && <StyledPendingLabel
            control={
              <Checkbox
                checked={!!pendingTerminationChecked}
                onChange={onChangePendingTermination}
              />
            }
            label="This employee is pending termination"
          />}
     
          {(!isAutomated && !isROGhost) && <ButtonGroup sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Button
              onClick={() => formRef.current?.reset()}
            >
              Cancel
            </Button>
            <Button 
              variant="contained"
              disabled={controlType === 'automated'}
              type='submit'
            >
              Save
            </Button>
          </ButtonGroup>}
        </Paper>
        <SaveSnackbar 
          open={showSnackbar.show}
          setOpen={(show) => {
            setShowSnackbar({...showSnackbar, show})
          }}
          statusMessages={{
            success: 'Employee Updated',
            error: 'Error Updating Employee'
          }}
          severity={showSnackbar.severity}
        />
      </StyledForm>
  )
}

export default withEmployee(EmployeeDetails);