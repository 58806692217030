import React from 'react'
import InfoCard from '../../../components/info-card'
import styled from '@mui/material/styles/styled'

const SideBySide = styled('div')(({theme}) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    marginBottom: '20px',
    gap: '20px', 
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
    }
  }
})
 
const Details = () =>  (
  <div>
    <p>A maximum upper dollar limit an employee can advance per pay period helps with the following:</p>
    <ul>
      <li>Mitigates the risk of payroll fraud by setting clear, enforceable limits on disbursements.</li>
      <li>Enhances financial stability by preventing excessive payroll advances, ensuring consistent cash flow management.</li>
    </ul>
  </div>
)

const AbsoluteMax = props => {

  const { 
    sdk: { 
      safeguards: { 
        absoluteMaxEligibleAmountPerPayPeriod, 
      },
      loadingStatus
    } ,
  } = props

  if (loadingStatus === 'pending') { return (<></>) }

  return (
    <SideBySide>
      <InfoCard 
        headerText={'Maximum Amount per Pay Period'}
        bodyText={'The most an employee can advance per pay period.'}
        valueText={`$${absoluteMaxEligibleAmountPerPayPeriod}`}
        learnMoreText={'Learn More'}
      />
      <Details />
    </SideBySide>
   
  )
}

export default AbsoluteMax