const primary = '#546A7B'
const secondary = '#C0D9DE'
const warning = '#df7422'
const success = '#a5c191'
const info = '#C0D9DE'
const sand = '#F8F7F5'
const darkSand = '#DEDBD4'

const defaultTheme = {
	palette: {
		mode: 'light',
		primary: {
			main: primary
		},
		secondary: {
			main: secondary,
			contrastText: primary,
		},
		success: {
			main: success
		},
		warning: {
			main: warning
		},
		info: {
			main: info
		},
		sand: {
			main: sand
		},
		darkSand: {
			main: darkSand
		}
	},
};

export default defaultTheme;
