import React from 'react';
import {createSvgIcon} from '@mui/material';

const MoneySendFilled = createSvgIcon(
	<svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M19.5555 29.6269C27.4102 29.6269 33.7777 23.5718 33.7777 16.1025C33.7777 8.63319 27.4102 2.57812 19.5555 2.57812C11.7008 2.57812 5.33325 8.63319 5.33325 16.1025C5.33325 23.5718 11.7008 29.6269 19.5555 29.6269Z" fill="#C0D9DE"/>
		<path d="M12.6667 18.3331C12.6667 19.6265 13.6668 20.6665 14.8934 20.6665H17.4001C18.4667 20.6665 19.3334 19.7598 19.3334 18.6265C19.3334 17.4132 18.8001 16.9732 18.0134 16.6932L14.0001 15.2932C13.2134 15.0132 12.6801 14.5865 12.6801 13.3598C12.6801 12.2398 13.5467 11.3198 14.6134 11.3198H17.1201C18.3467 11.3198 19.3468 12.3598 19.3468 13.6532" stroke="#546A7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M16 10V22" stroke="#546A7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M29.3334 15.9998C29.3334 23.3598 23.3601 29.3332 16.0001 29.3332C8.64008 29.3332 2.66675 23.3598 2.66675 15.9998C2.66675 8.63984 8.64008 2.6665 16.0001 2.6665" stroke="#546A7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M29.3333 7.99984V2.6665H24" stroke="#546A7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M22.6667 9.33317L29.3334 2.6665" stroke="#546A7B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>,
	'MoneySendFilled'
)

export default MoneySendFilled