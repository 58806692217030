import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withActiveEmployee(Component) {
	return (props) => {

		const employee = useSelector(state => state.myEmployees.employee)
		const loadingActiveStatus = useSelector(state => state.myEmployees.employeeActiveStatus)
		
    const dispatch = useDispatch()
		const employeeActiveSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			employee,
			loadingActiveStatus,
		}
		const passThruProps = {...props, employeeActiveSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withActiveEmployee