const button = {
	MuiButton: {
		styleOverrides: {
			root: ({ theme }) => ({
				textTransform: 'capitalize',
				boxShadow: 'none',
				'&:hover': {
					boxShadow: 'none,'
				},
				[theme.breakpoints.up('sm')]: {
					fontSize: '18px'
				}
			}),
			sizeLarge: {
				borderRadius: '15px'
			},
			sizeMedium: {
				borderRadius: '10px'
			},
			sizeSmall: {
				borderRadius: '8px'
			}
		}
	},
	MuiIconButton: {
		styleOverrides: {
			root: {
				textTransform: 'capitalize',
				boxShadow: 'none',

				'&:hover': {
					boxShadow: 'none'
				}
			},
			sizeLarge: {
				borderRadius: '15px'
			},
			sizeMedium: {
				borderRadius: '10px'
			},
			sizeSmall: {
				borderRadius: '8px'
			}
		}
	},
}

export default button