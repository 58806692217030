

const funcDispatchWrapper = (dispatch, funcs) => Object.keys(funcs).reduce((wrapped, key) => {
  return {
    ...wrapped,
    [key]: (...args) => {
      dispatch(funcs[key](...args))
    }
  }
}, {})

export default funcDispatchWrapper