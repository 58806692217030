import React from "react"
import { useNavigate } from "react-router-dom"

const CopyPasteUrl = () => { 

  const navigate = useNavigate()
  
  React.useEffect(() => {
    const redirect = window.localStorage.getItem('redirectPath')
    if(redirect){
      navigate(redirect)
      window.localStorage.removeItem('redirectPath')
    }
  },[navigate])

  return (<></>)
}

export default CopyPasteUrl