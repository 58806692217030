

import React from 'react';
import {createSvgIcon} from '@mui/material';

const HeartSuccess = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
    <path d="M74.5334 53.7334C63.0501 53.7334 53.7334 63.0501 53.7334 74.5334C53.7334 78.4334 54.8167 82.1167 56.7667 85.2367C60.3634 91.2601 66.9501 95.3334 74.5334 95.3334C82.1167 95.3334 88.7467 91.2601 92.3001 85.2367C94.2067 82.1167 95.3334 78.4334 95.3334 74.5334C95.3334 63.0501 86.0167 53.7334 74.5334 53.7334ZM84.8467 71.8034L73.7534 82.0301C73.1467 82.5934 72.3234 82.8967 71.5434 82.8967C70.7201 82.8967 69.8967 82.5934 69.2467 81.9434L64.1334 76.8301C62.8767 75.5734 62.8767 73.4934 64.1334 72.2367C65.3901 70.9801 67.4701 70.9801 68.7267 72.2367L71.6301 75.1401L80.4267 66.9934C81.7267 65.7801 83.8067 65.8667 85.0201 67.1667C86.2334 68.5101 86.1467 70.5901 84.8467 71.8034Z" fill="#546A7B"/>
    <path d="M91.7258 51.4832C86.7604 47.8356 80.7309 45.9064 74.5332 45.9064C58.6681 45.9064 45.7332 58.8413 45.7332 74.7064C45.7332 79.6461 47.0139 84.493 49.4365 88.7898L49.4408 88.7973C49.443 88.8011 49.4451 88.8049 49.4471 88.8087C44.4125 87.0326 36.878 83.0463 29.7454 76.9163C22.4526 70.6487 15.6661 62.2146 12.3501 51.7211L12.3502 51.7211L12.347 51.7114C10.9633 47.4344 10.1665 42.8233 10.1665 37.8298C10.1665 25.2612 20.2919 15.1064 32.7598 15.1064C40.1085 15.1064 46.6919 18.679 50.7988 24.1684L51.9998 25.7737L53.2009 24.1684C57.3078 18.679 63.8912 15.1064 71.2398 15.1064C83.7077 15.1064 93.8332 25.2612 93.8332 37.8298C93.8332 42.7346 93.0645 47.2705 91.7258 51.4832Z"  stroke="#546A7B" strokeWidth="3"/>
  </svg>
	,
	'HeartSuccess'
)

export default HeartSuccess

