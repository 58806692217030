import axios from 'axios'


//-----------------
// Setup singleton api client
//------------------
export const apiClient = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	}
})

apiClient.interceptors.response.use(response => {
	if(response.status === 401 || response.status === 403) {
		console.log('Unauthorized')
    console.log('Need to log out user')
	}
	return response
})


//-----------------
// Get auth interceptor with tokenAccessor function passed by auth setup
// Token accessor will also refresh token if needed
//------------------
const getAuthInterceptor = (tokenAccessor, logout) => {
	return async (config) => {
		try {
			//-------
			// Access token or refresh if needed
			//-------
			const accessToken = await tokenAccessor()
			//------
			// Set access token in header
			//------
			return {
				...config,
				headers: {
					...config.headers,
					authorization: `Bearer ${accessToken}`
				}
			}
		} catch(e) {
			//-----
			// Token accessor errs if user is not logged in
			//-----
			window.localStorage.clear()
			window.location.href = '/'
			logout({
				logoutParams: {
					returnTo: window.location.origin,
				}
			})

			// throw new Error('Not Authorized')
		}
	}
}


export const usingAccessToken = (tokenAccessor, logout) => {
	apiClient.interceptors.request.use(getAuthInterceptor(tokenAccessor, logout))
}
