import React from 'react'
import InfoCard from '../../../components/info-card'
import styled from '@mui/material/styles/styled'

const SideBySide = styled('div')(({theme}) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    marginBottom: '20px',
    gap: '20px', 
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
    }
  }
})


const Details = () => (
  <div>
    <p>The maximum percentage helps with the following:</p>
    <ul>
      <li>Prevents an employee from advancing too much of a paycheck, preserving a buffer for other deductions like taxes and garnishments.</li>
      <li>Helps maintain a safe margin to accommodate fluctuations in other payroll deductions, ensuring employees do not receive less than expected on payday.</li>
      <li>Reduces the risk associated with payroll inaccuracies, particularly where timekeeping adjustments are frequent.</li>
   </ul>
  </div>
)

const MaxPercentage = props => {

  const { 
    sdk: { 
      safeguards: { 
        earningsPercentageLimit, 
        stablHardLimits 
      },
      loadingStatus
    } ,
    onChange
  } = props

  if (loadingStatus === 'pending') { return (<></>) }

  return (
    <SideBySide>
      <InfoCard 
        headerText={'Maximum Percentage'}
        bodyText={'The percentage of earnings an employee can advance.'}
        valueText={`${earningsPercentageLimit}%`}
        learnMoreText={'Learn More'}
      />
      <Details />
    </SideBySide>
  )
}

export default MaxPercentage