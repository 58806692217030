import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const LoginPage = () => {

	const {
		isAuthenticated,
		loginWithRedirect,
	} = useAuth0();

	if(!isAuthenticated) return loginWithRedirect()
	return (<div />);
};

export default LoginPage;
