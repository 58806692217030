import React from "react";
import {Box, Button, ButtonGroup, Modal, Paper, TextField, Typography} from '@mui/material'
import styled from "@mui/material/styles/styled";
import AddIcon from "@mui/icons-material/Add";
import withEmailRecipients from "../sdk/withEmailRecipients";
import EmailRecipient from "../email-recipient";
import SaveSnackbar from "../../../components/save-snackbar";

const Section = styled('section')(() => ({
    marginBottom: '32px',
}))

const StyledHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
}))

const StyledDescription = styled('div')(({ theme }) => ({
    marginTop: '20px',
    marginBottom: '40px'
}))

const ModalContent = styled(Box)(({theme}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
}))

const BACKDROP_CLICK_REASON = 'backdropClick'

const DeductionRecipients = (props) => {
    const {
        emailRecipientsSDK: {
            createEmailRecipientStatus,
            emailRecipients,
            emailRecipientsStatus,
            getEmailRecipients,
            createEmailRecipient,
            resetCreateEmailRecipient,
            primaryEmailRecipientStatus,
            resetPrimaryEmailRecipient,
            deleteEmailRecipientStatus,
            resetDeleteEmailRecipient,
        },
    } = props

    const [showEmailAdd, setShowEmailAdd] = React.useState(false)
    const [createError, setCreateError] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const emailRef = React.useRef()

    const [showCreateSnackbar, setShowCreateSnackbar] = React.useState({show: false, severity: 'success'})
    const [showPrimarySnackbar, setShowPrimarySnackbar] = React.useState({show: false, severity: 'success'})
    const [showDeleteSnackbar, setShowDeleteSnackbar] = React.useState({show: false, severity: 'success'})

    React.useEffect(() => {
        if (emailRecipientsStatus === 'fulfilled') setLoading(false)
    }, [emailRecipientsStatus])

    React.useEffect(() => {
        setLoading(true)
        getEmailRecipients()
    }, [])

    React.useEffect(() => {
        if (createEmailRecipientStatus === 'fulfilled') {
            getEmailRecipients()
            setShowEmailAdd(false)
            resetCreateEmailRecipient()
            setShowCreateSnackbar({show: true, severity: 'success'})
        }
        if (createEmailRecipientStatus === 'error') {
            setShowCreateSnackbar({show: true, severity: 'error'})
        }
    }, [createEmailRecipientStatus])


    React.useEffect(() => {
        if (primaryEmailRecipientStatus === 'fulfilled') {
            resetPrimaryEmailRecipient()
            setShowPrimarySnackbar({show: true, severity: 'success'})
        }
        if (primaryEmailRecipientStatus === 'error') {
            setShowPrimarySnackbar({show: true, severity: 'error'})
        }
    }, [primaryEmailRecipientStatus])

    React.useEffect(() => {
        if (deleteEmailRecipientStatus === 'fulfilled') {
            resetDeleteEmailRecipient()
            setShowDeleteSnackbar({show: true, severity: 'success'})
        }
        if (deleteEmailRecipientStatus === 'error') {
            setShowDeleteSnackbar({show: true, severity: 'error'})
        }
    }, [deleteEmailRecipientStatus])


    const handleModalClose = (_, reason) => {
        if (reason !== BACKDROP_CLICK_REASON) {
            setShowEmailAdd(false)
        }
    }

    const addEmailRecipient = () => {
        if (emailRef.current.value)
            createEmailRecipient(emailRef.current.value)
    }

    return (<Section>
        <StyledHeader>
            <Typography
                sx={{marginBottom: '16px'}}
                variant="h6"
                color='primary'
            >
                Deduction Report Recipients
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setShowEmailAdd(true)}
            >
                <AddIcon />
                Add Recipient
            </Button>
        </StyledHeader>

        <StyledDescription>
            <Typography variant="p">
                Email addresses that will receive the payroll deduction reports.
            </Typography>
        </StyledDescription>

        {emailRecipients.map((recipient) => (
            <EmailRecipient
                key={recipient.id}
                recipient={recipient}
                loading={loading}
            />
        ))}

        <SaveSnackbar
            open={showCreateSnackbar.show}
            setOpen={(show) => {
                setShowCreateSnackbar({...showCreateSnackbar, show})
            }}
            statusMessages={{
                success: 'Deduction report email recipient added.',
                error: "An error has occurred. Please try again later."
            }}
            severity={showCreateSnackbar.severity}
        />

        <SaveSnackbar
            open={showDeleteSnackbar.show}
            setOpen={(show) => {
                setShowDeleteSnackbar({...showDeleteSnackbar, show})
            }}
            statusMessages={{
                success: 'Email has been removed.',
                error: "An error has occurred. Please try again later."
            }}
            severity={showDeleteSnackbar.severity}
        />

        <SaveSnackbar
            open={showPrimarySnackbar.show}
            setOpen={(show) => {
                setShowPrimarySnackbar({...showPrimarySnackbar, show})
            }}
            statusMessages={{
                success: 'New primary email set.',
                error: "An error has occurred. Please try again later."
            }}
            severity={showPrimarySnackbar.severity}
        />

        <Modal
            open={showEmailAdd}
            onClose={handleModalClose}
        >
            <ModalContent>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add new Deduction Report Recipient
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Enter the email address that should receive deduction reports.
                </Typography>

                <TextField
                    sx={{ margin: '7px 0'}}
                    inputRef={emailRef}
                    placeholder="Email"
                    helperText={createError}
                    error={!!createError}
                    fullWidth
                />

                <ButtonGroup sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '12px'
                }}>
                    <Button
                        onClick={handleModalClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={addEmailRecipient}
                    >
                        Submit
                    </Button>
                </ButtonGroup>

            </ModalContent>
        </Modal>
    </Section>)
}

export default withEmailRecipients(DeductionRecipients)