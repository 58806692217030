const constants = {
    CREATE_ADMIN: 'my-settings/CREATE_ADMIN',
    GET_ALL_ADMINS: 'my-settings/GET_ALL_ADMINS',
    RESET_ALL_ADMINS: 'my-settings/RESET_ALL_ADMINS',
    DELETE_ADMIN: 'my-settings/DELETE_ADMIN',
    RESET_DELETE_ADMIN: 'my-settings/RESET_DELETE_ADMIN',

    CREATE_EMAIL_RECIPIENT: 'my-settings/CREATE_EMAIL_RECIPIENT',
    RESET_CREATE_EMAIL_RECIPIENT: 'my-settings/RESET_CREATE_EMAIL_RECIPIENT',
    GET_EMAIL_RECIPIENTS: 'my-settings/GET_EMAIL_RECIPIENTS',
    DELETE_EMAIL_RECIPIENT: 'my-settings/DELETE_EMAIL_RECIPIENT',
    RESET_DELETE_EMAIL_RECIPIENT: 'my-settings/RESET_DELETE_EMAIL_RECIPIENT',
    SET_PRIMARY_EMAIL_RECIPIENT: 'my-settings/SET_PRIMARY_EMAIL_RECIPIENT',
    RESET_SET_PRIMARY_EMAIL_RECIPIENT: 'my-settings/RESET_SET_PRIMARY_EMAIL_RECIPIENT',
}
export default constants