import React from 'react'
import InfoCard from '../../../components/info-card'
import styled from '@mui/material/styles/styled'

const SideBySide = styled('div')(({theme}) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    marginBottom: '20px',
    gap: '20px', 
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
    }
  }
})

const Details = () =>  (
  <div>
  <p>The maximum numbers of hours per day is straight forward, it is set to limit the number of hours your employees can clock a day.</p>
  <ul>
    <li>This prevents overclocking, advancing, then adjusting hours back to actual worked hours; and many other manipulation efforts to increase advance eligibility amounts.</li>
 </ul>
</div>
)

const MaxDailyHours = props => {

  const { 
    sdk: { 
      safeguards: { 
        maxHoursPerDay, 
      },
      loadingStatus
    } ,
  } = props

  if (loadingStatus === 'pending') { return (<></>) }

  return (
    <SideBySide>
      <InfoCard 
        headerText={'Maximum Hours per Day'}
        bodyText={'The most hours an employee clock per day.'}
        valueText={`${maxHoursPerDay} Hr${maxHoursPerDay === 1 ? '' : 's'}`}
      />
      <Details />
    </SideBySide>
  
  )
}

export default MaxDailyHours