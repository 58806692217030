import React from "react";
import {
    Box,
    ButtonGroup,
    CardContent,
    Card,
    Button,
    Paper,
    Typography,
    Modal,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton
} from "@mui/material";

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveSnackbar from "../../../../components/save-snackbar";
import withResetMFA from "../../sdk/withMFAReset";
import { useLocation } from "react-router-dom";
import styled from "@mui/material/styles/styled";
import Skeletonized from "./skeletonized";

const StyledForm = styled("form")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "16px 0",
}));

const StyledHeader = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
}));

const StyledContent = styled(CardContent)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    flexGrow: 1,
    flexWrap: 'wrap',
}));

const StyledCard = styled(Card)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    alignItems: "flex-start",
    height: "100%",
    padding: "16px",
    minWidth: '200px',
    borderBottom: "1px solid #e0e0e0",
}));

const MethodType = styled(Typography)(() => ({
    textTransform: 'capitalize',
    marginBottom: '10px'
}))

const ModalContent = styled(Box)(({theme}) => ({
    position: 'absolute',
        top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
}))

const ExtraSmallIconButton = styled(IconButton)(({theme}) => ({
    fontSize: '1em',
    '& svg': {
        fontSize: '1.25em'
    }
}))


const MethodView = (id, type, onReset) => {
    const displayType = type.replace(/-/g, ' ')

    return (
        <StyledCard>
            <MethodType variant="h7" color="primary">
                {displayType}
            </MethodType>
            <Button onClick={() => onReset(id)} size="large" variant="outlined" fullWidth>
                Reset
            </Button>
        </StyledCard>
    )
}



const EmployeeMfa = (props) => {
    const location = useLocation()
    const [ employeeID ] = location.pathname.split('/').reverse()

    const {
        mfaResetSDK: {
            resetAuth0MFAMethods,
            removeAuth0MFAMethod,
            employeeMfaMethods,
            employeeMfaMethodsStatus,
            createOtpStatus,
            createOtpError,
            createEwaEmployeeOtp,
            resetMfaMethodStatus,
            resetMfaMethodError,
            removeAuth0MFAMethodWithOtp,
            getAuth0MFAMethods
        },
    } = props

    const [ showSnackbar, setShowSnackbar ] = React.useState({show: false, severity: 'success'})
    const [ methodIdToReset, setMethodIdToReset] = React.useState(null)
    const [ showMfaConfirm, setShowMfaConfirm] = React.useState(false)
    const [ showOtpConfirm, setShowOtpConfirm] = React.useState(false)
    const [ snackbarError, setSnackbarError] = React.useState('')

    const otpRef = React.useRef()

    const isROGhost = sessionStorage.getItem('ghosting-role') === 'read-only'

    React.useEffect(() => {
        getAuth0MFAMethods(employeeID)
        return () => {
            resetAuth0MFAMethods()
        }
    }, [])


    const requestReset = (methodId) => {
        setMethodIdToReset(methodId)
        setShowMfaConfirm(true)
    }


    const handleReset = (e) => {
        e.preventDefault()
        if (isROGhost) {
            createEwaEmployeeOtp(employeeID)
        }
        else {
            removeAuth0MFAMethod(employeeID, methodIdToReset)
        }
    }


    const otpConfirmReset = () => {
        removeAuth0MFAMethodWithOtp(employeeID, methodIdToReset, otpRef.current.value)
    }

    //--------
    // Handle OTP request status
    //--------
    React.useEffect(() => {
        if (createOtpStatus === 'fulfilled') {
            setShowMfaConfirm(false)
            setShowOtpConfirm(true)
            setShowSnackbar({show: true, severity: 'success'})
        }
        if (createOtpStatus === 'rejected') {
            setShowMfaConfirm(false)
            setShowOtpConfirm(false)
            setMethodIdToReset(null)
            setSnackbarError(createOtpError)
            setShowSnackbar({show: true, severity: 'error'})
        }
    }, [createOtpStatus])



    //--------
    // Handle MFA reset status
    //--------
    React.useEffect(() => {
        if (resetMfaMethodStatus === 'fulfilled') {
            setShowMfaConfirm(false)
            setShowOtpConfirm(false)
            setMethodIdToReset(null)
            setShowSnackbar({show: true, severity: 'success'})
        }
        if (resetMfaMethodStatus === 'rejected') {
            setShowMfaConfirm(false)
            setShowOtpConfirm(false)
            setMethodIdToReset(null)
            setSnackbarError(resetMfaMethodError)
            setShowSnackbar({show: true, severity: 'error'})
        }
    }, [resetMfaMethodStatus])



    if (employeeMfaMethodsStatus !== 'fulfilled') {
        return <Skeletonized />
    }

    // const { firstName } = employee
    const mfaMethodType = methodIdToReset ?
        employeeMfaMethods.find(({id}) => id === methodIdToReset)?.type :
        null

    const displayType = mfaMethodType?.replace(/-/g, ' ')

    return (
        <StyledForm>
            <StyledHeader>
                <Typography sx={{marginBottom: '16px'}} variant="h6" color='primary'>Multi Factor Authentication</Typography>
            </StyledHeader>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Identifier</TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {employeeMfaMethods.length ? employeeMfaMethods.map((row, index) => (
                    <TableRow
                    key={row.id+index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row" sx={{textTransform: 'capitalize'}}>
                        {row.type}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{textTransform: 'capitalize'}}>
                        {row.phone_number}
                    </TableCell>
                    <TableCell align="right">
                        <ExtraSmallIconButton onClick={() => requestReset(row.id)}>
                            Reset <RestartAltIcon />
                        </ExtraSmallIconButton>
                    </TableCell>
                    </TableRow>
                )) : (
                    <TableRow>
                        <TableCell colSpan={2} align="center">
                            No MFA methods found
                        </TableCell>
                    </TableRow>
                )}
                </TableBody>
            </Table>
            </TableContainer>
            <SaveSnackbar
                open={showSnackbar.show}
                setOpen={(show) => {
                    setShowSnackbar({...showSnackbar, show})
                }}
                statusMessages={{
                    success: 'MFA has been reset successfully. The employee has received an email inviting them to set up a new MFA.',
                    error: snackbarError
                }}
                severity={showSnackbar.severity}
            />

            <Modal
                open={showMfaConfirm}
                onClose={() => {
                    setShowMfaConfirm(false)
                    setMethodIdToReset(null)
                }}
            >
                <ModalContent>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Reset {displayType} MFA?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Once reset, the employee will need to re-enroll in MFA on their next login.
                    </Typography>
                    <ButtonGroup sx={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Button
                            onClick={() => {
                                setShowMfaConfirm(false)
                                setMethodIdToReset(null)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                    </ButtonGroup>
                </ModalContent>
            </Modal>


            <Modal
                open={showOtpConfirm}
                onClose={() => setShowOtpConfirm(false)}
            >
                <ModalContent>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Enter One Time Password
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Enter the one time password that was emailed to the user.
                    </Typography>
                    <TextField
                        inputRef={otpRef}
                        placeholder="OTP"
                        fullWidth
                        helperText={resetMfaMethodError}
                        error={!!resetMfaMethodError}
                        inputProps={{
                            maxLength: 6
                        }}
                    />
                    <ButtonGroup sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Button
                            onClick={() => {
                                setShowOtpConfirm(false)
                                setMethodIdToReset(null)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={otpConfirmReset}
                        >
                            Submit
                        </Button>
                    </ButtonGroup>
                </ModalContent>
            </Modal>
        </StyledForm>
    )
}

export default withResetMFA(EmployeeMfa)