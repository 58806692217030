import React from 'react'
import { 
  Paper,
  Typography,
  TextField,
  Button,
  ButtonGroup,
  InputAdornment,
} from '@mui/material'
import styled from '@mui/material/styles/styled'
import SaveSnackbar from '../../../components/save-snackbar'
import withCreateEmployee from '../sdk/withCreateEmployee'
import deepEqual from 'deep-equal'

const StyledField = styled(TextField)(({ theme }) => ({
  margin: '0 32px 12px 0',
}))

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: "16px 0"
}))

const StyledRow = styled('div')(({ theme }) => ({
  justifyContent: 'flex-start',
  display: 'flex',
  width: '100%',
  padding: '16px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '16px 0',
  }
}))

const StyledHeader = styled('div')(({ theme }) => ({ 
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-staft',
  justifyContent: 'flex-start'
}))

const Safeguards = props => {

  const { employeeSDK: {
    createdEmployee,
    createdEmployeeSafeguards,
    findEmployeeSafeguards,
    updateEmployeeSafeguards,
    createdEmployeeSafegaurdsStatus
  }} = props 

  const { companyHardLimits } = createdEmployeeSafeguards

  const [ afterLoad, setAfterLoad ] = React.useState(false)
  const [ maxHoursError, setMaxHoursError ] = React.useState(null)
  const [ maxAdvanceError, setMaxAdvanceError ] = React.useState(null)
  const [ maxPercentageError, setMaxPercentageError ] = React.useState(null)
  const [ formValues, setFormValues ] = React.useState(createdEmployeeSafeguards)
  const [ showSnackbar, setShowSnackbar ] = React.useState({show: false, severity: 'info', message: ''})
  
  const pluralize = (word, value) => parseInt(value) !== 1 ? `${word}s` : word 
  const isAllNumbers = value => /^\d+$/.test(value) ? null : 'Must be a number'
  const isBelowLimit = (limit, display) => value => value <= limit ? null : `Must be at or below ${display}`
  
  React.useEffect(() => {
    // load employee safegaurds after creating the employee
    if(!!createdEmployee) findEmployeeSafeguards(createdEmployee.id)

    // turn off snackbar when creating another
    else if(!createdEmployee && afterLoad) setAfterLoad(false)
  }, [ createdEmployee ])

  React.useEffect(() => {
    if(!deepEqual(createdEmployeeSafeguards, formValues)) {
      setFormValues(createdEmployeeSafeguards)
    }
  }, [createdEmployeeSafeguards])

  React.useEffect(() => {

    // only show the snackbar on save *after* the initial load
    if(afterLoad){
      if(createdEmployeeSafegaurdsStatus === 'fulfilled'){
        setShowSnackbar({show: true, severity: 'success', message: 'Employee Created'})
      } else if(createdEmployeeSafegaurdsStatus === 'rejected') {
        setShowSnackbar({show: true, severity: 'error', message: 'Error Creating Employee'})
      } else {
        setShowSnackbar({show: false, severity: 'success', message: 'Placeholder'})
      }
    } else if(!!createdEmployee && createdEmployeeSafegaurdsStatus === 'fulfilled'){
      setAfterLoad(true)
    }
    
  }, [createdEmployeeSafegaurdsStatus])

  const maxHoursCheck = e => {
    const { value, name } = e.target
    setMaxHoursError(isAllNumbers(value) || isBelowLimit(companyHardLimits.maxHours, `${companyHardLimits.maxHours} ${pluralize('hour', companyHardLimits.maxHours)}`)(value))
    setFormValues({...formValues, [name]: value})
  }

  const maxAdvanceCheck = e => {
    const { value, name } = e.target
    setMaxAdvanceError(isAllNumbers(value) || isBelowLimit(companyHardLimits.maxAdvance, `$${companyHardLimits.maxAdvance}`)(value))
    setFormValues({...formValues, [name]: value})
  }

  const maxPercentageCheck = e => { 
    const { value, name } = e.target
    setMaxPercentageError(isAllNumbers(value) || isBelowLimit(companyHardLimits.maxPercentage, `${companyHardLimits.maxPercentage}%`)(value))
    setFormValues({...formValues, [name]: value})
  }


  const onSaveSafegauards = () => {
    updateEmployeeSafeguards(createdEmployee.id, formValues)
  }

  const resetSafeguards = () => {
    setFormValues(createdEmployeeSafeguards)
    setMaxHoursError(null)
    setMaxAdvanceError(null)
    setMaxPercentageError(null)
  }

  return (
    <StyledForm
    >
      <StyledHeader>
        <Typography variant="h6" sx={{marginBottom: '16px'}}  color='primary'>Individual Safeguards</Typography>
      </StyledHeader>
      <Paper sx={{padding: '16px'}}>
        <StyledRow>
          <StyledField 
            fullWidth
            required
            name="maxHours"
            
            label="Max Hours per Day"
            value={formValues.maxHours}
            variant='standard'
            onChange={maxHoursCheck}
            error={!!maxHoursError}
            helperText={maxHoursError || ''}
            InputProps={{
              endAdornment: <InputAdornment position="start">Hours</InputAdornment>,
              readOnly: !createdEmployee
            }}
          />
          <StyledField 
            fullWidth
            required
            name="maxAdvance"
            label="Max Amount per Pay Period"
            value={formValues.maxAdvance}
            variant='standard'
            onChange={maxAdvanceCheck}
            error={!!maxAdvanceError}
            helperText={maxAdvanceError || ''}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              readOnly: !createdEmployee
            }}
          />
          <StyledField 
            fullWidth
            required
            name="maxPercentage"
            label="Max Percentage of Gross Pay"
            value={formValues.maxPercentage}
            variant='standard'
            onChange={maxPercentageCheck}
            error={!!maxPercentageError}
            helperText={maxPercentageError || ''}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
              readOnly: !createdEmployee
            }}
          />
        </StyledRow>
        <ButtonGroup sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Button
              disabled={!createdEmployee}
              onClick={resetSafeguards}
            >
              Reset
            </Button>
            <Button 
              variant="contained"
              onClick={onSaveSafegauards}
              disabled={!createdEmployee ||!!maxHoursError || !!maxAdvanceError || !!maxPercentageError}
            >
              Save
            </Button>
          </ButtonGroup>
      </Paper>
      <SaveSnackbar  
        open={showSnackbar.show}
        setOpen={(show) => {
          setShowSnackbar({...showSnackbar, show})
        }}
        statusMessages={{
          success: 'Employee Safeguards Updated',
          error: 'Error Updating Employee Safeguards'
        }}
        severity={showSnackbar.severity}
      />
    </StyledForm>
  )
}

export default withCreateEmployee(Safeguards)