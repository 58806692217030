import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function WithInternalNotes(Component) {
    return (props) => {

        const latestInternalNote = useSelector(state => state.myEmployees.latestInternalNote)
        const createInternalNotesStatus = useSelector(state => state.myEmployees.createInternalNotesStatus)
        const createInternalNotesError = useSelector(state => state.myEmployees.createInternalNotesError)
        const dispatch = useDispatch()

        const internalNotesSDK = {
            ...dispatchWrapper(dispatch, api(apiClient)),
            latestInternalNote,
            createInternalNotesStatus,
            createInternalNotesError,
        }
        const passThruProps = {...props, internalNotesSDK}

        return (
            <Component {...passThruProps} />
        )
    }
}

export default WithInternalNotes