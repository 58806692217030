import React from 'react';
import PageHeader from '../../components/page-header';
import { 
  Typography, 
  Box,
  Divider,
  Snackbar,
  Alert
} from '@mui/material';
import MaxPercentage from './max-percentage';
import AbsoluteMax from './absolute-maximum';
import MaxDailyHours from './max-hours';
import withSafeguards from './sdk/withSafeguards';
import { debounce } from '../../utils';
import { styled } from '@mui/material/styles';

const StyledPage = styled(Box)(({theme}) => ({
  padding: theme.spacing(2),
  '& > *:not(last-of-type)': {
    marginBottom: theme.spacing(2)
  }
}))

const StyledSafegaurd = styled('div')(({theme}) => ({
  padding: '16px 0',
}))

const SafeGuards = props => {
  const { safeguardsSDK, safeguardsSDK: { findMyCompanySafeguards, patchMyCompanySafeguards, patchStatus, resetPatchStatus } } = props
  const [ open, setOpen ] = React.useState(false)
  const [ type, setType ] = React.useState('success')

  const onChange = key => debounce((_, value) => patchMyCompanySafeguards({[key]: value}), 250)
  
  React.useEffect(() => { 
    findMyCompanySafeguards()
  }, [])

  React.useEffect(() => {
    window.scroll(0,0)
  },[])

  React.useEffect(() => {

    if(patchStatus === 'fulfilled'){
      setType('success')
      setOpen(true)
      resetPatchStatus()
    }
    else if(patchStatus === 'rejected'){
      setOpen(true)
      setType('error')
      resetPatchStatus()
    }
  }, [patchStatus])

  return (
    <Box>
      <PageHeader><Typography variant='h5'>SafeGuards</Typography></PageHeader>
      <StyledPage>
        <StyledSafegaurd><MaxPercentage sdk={safeguardsSDK} onChange={onChange}/></StyledSafegaurd>
        <Divider />
        <StyledSafegaurd><MaxDailyHours sdk={safeguardsSDK} onChange={onChange}/></StyledSafegaurd>
        <Divider />
        <StyledSafegaurd><AbsoluteMax sdk={safeguardsSDK} onChange={onChange}/></StyledSafegaurd>
      </StyledPage>
        <Snackbar 
          open={open} 
          autoHideDuration={6000} 
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
        >
        <Alert
          onClose={() => setOpen(false)}
          severity={type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          { type === 'success' ? 'Safeguard updated successfully' : 'Failed to update safeguard'}
        </Alert>
      </Snackbar>
    </Box>
  )
}
export default withSafeguards(SafeGuards)