import React from 'react'
import styled from '@mui/material/styles/styled'
import { 
  Paper,
  Typography,
  TextField,
  ButtonGroup,
  Button
 } from '@mui/material';
import withInternalNotes from "../../sdk/withInternalNotes";
import withEmployee from "../../sdk/withEmployee";
import SaveSnackbar from "../../../../components/save-snackbar";

const StyledHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-staft",
  justifyContent: "flex-start",
  padding: "16px 0",
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  margin: '16px 6px 16px 0'
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '16px',
}))

const InternalNotes = (props) => {

  const {
    employeeSDK: {
      employee
    },
    internalNotesSDK: {
      latestInternalNote,
      getLatestInternalNotes,
      createInternalNotes,
      createInternalNotesStatus,
      resetInternalNotes
    }
  } = props

  const [ value, setValue ] = React.useState(latestInternalNote.note)
  const [ showSnackbar, setShowSnackbar ] = React.useState({show: false, severity: 'success'})

  const isGhost = sessionStorage.getItem('ghosting-role') === 'read-only'

  React.useEffect(() => {
    if (!isGhost && employee && employee.id) getLatestInternalNotes(employee.id)
  }, [employee])

  React.useEffect(() => {
    setValue(latestInternalNote.note)
  }, [latestInternalNote])

  React.useEffect(() => {
    return () => resetInternalNotes()
  }, [])


  React.useEffect(() => {
    if (createInternalNotesStatus === 'fulfilled') {
      setShowSnackbar({show: true, severity: 'success'})
    }
    if (createInternalNotesStatus === 'rejected') {
      setShowSnackbar({show: true, severity: 'error'})
    }
  }, [createInternalNotesStatus])

  
  const onSubmit = (e) => {
    e.preventDefault()
    createInternalNotes(employee.id, value)
  }

  const onReset = () => {
    setValue(latestInternalNote.note)
  }

  const onChange = e => {
    setValue(e.target.value)
  }

  const isROGhost = sessionStorage.getItem('ghosting-role') === 'read-only'
  if(isROGhost) return null

  return (
    <>
    <StyledHeader>
      <Typography variant="h6">Internal Notes</Typography>
    </StyledHeader>
    <StyledForm onSubmit={onSubmit}>
      <StyledPaper>
        <TextField
          multiline
          fullWidth
          rows={6}
          value={value}
          onChange={onChange}
        />
        <StyledButtonGroup>
          <Button
            onClick={onReset}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            type='submit'
          >Save</Button>
        </StyledButtonGroup>
      </StyledPaper>

      <SaveSnackbar
          open={showSnackbar.show}
          setOpen={(show) => {
            setShowSnackbar({...showSnackbar, show})
          }}
          statusMessages={{
            success: 'Employee internal notes has successfully been updated.',
            error: "An error has occurred. Please try again later."
          }}
          severity={showSnackbar.severity}
      />
    </StyledForm>

    </>
  )
}

export default withEmployee(withInternalNotes(InternalNotes))