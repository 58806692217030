import constants from './constants'

const initState = {
  safeguardStatus: 'idle',
  patchStatus: 'idle',
  safeguards: {
    absoluteMaxEligibleAmountPerPayPeriod: 0,
    earningsPercentageLimit: 0,
    maxHoursPerDay: 0,
    stablHardLimits: {
      absoluteMaxEligibleAmountPerPayPeriod: 875,
      earningsPercentageLimit: 50,
      maxHoursPerDay: 10,
    }
  },
}

const reducer = (state= initState, action) => {
  switch(action.type) {
    case `${constants.FIND_MY_COMPANY_SAFEGUARDS}/pending`: {
      return {
        ...state,
        safeguardStatus: 'pending'
      }
    }
    case `${constants.PATCH_MY_COMPANY_SAFEGUARD}/fulfilled`:{ 
      return {
        ...state,
        patchStatus: 'fulfilled',
        safeguards: {
          absoluteMaxEligibleAmountPerPayPeriod: action.payload.absolute_max_eligible_amount_per_pay_period,
          earningsPercentageLimit: action.payload.earnings_percentage_limit,
          maxHoursPerDay: action.payload.max_hours_per_day,
          stablHardLimits: {
            absoluteMaxEligibleAmountPerPayPeriod: action.payload.stabl_hard_limits.absolute_max_eligible_amount_per_pay_period,
            earningsPercentageLimit: action.payload.stabl_hard_limits.earnings_percentage_limit,
            maxHoursPerDay: action.payload.stabl_hard_limits.max_hours_per_day,
          }
        }
      }
    }
    case `${constants.FIND_MY_COMPANY_SAFEGUARDS}/fulfilled`: { 
      return {
        ...state,
        safeguardStatus: 'fulfilled',
        safeguards: {
          absoluteMaxEligibleAmountPerPayPeriod: action.payload.absolute_max_eligible_amount_per_pay_period,
          earningsPercentageLimit: action.payload.earnings_percentage_limit,
          maxHoursPerDay: action.payload.max_hours_per_day,
          stablHardLimits: {
            absoluteMaxEligibleAmountPerPayPeriod: action.payload.limits.absolute_max_eligible_amount_per_pay_period,
            earningsPercentageLimit: action.payload.limits.earnings_percentage_limit,
            maxHoursPerDay: action.payload.limits.max_hours_per_day,
          }
        }
      }
    }
    case `${constants.PATCH_MY_COMPANY_SAFEGUARD}/rejected`: {
      return {
        ...state,
        patchStatus: 'rejected'
      }
    }
    case `${constants.RESET_PATCH_STATUS}`: {
      return {
        ...state,
        patchStatus: 'idle'
      }
    }
    default: return {...state}
  }

}

export default reducer