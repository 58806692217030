import Safeguards from "./safeguards";
import Details from './details';
import Header from "./header";

export default function Add(){
  return (
    <div>
      <Header/ >
      <Details />
      <Safeguards />
    </div>
  )
}