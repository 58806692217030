import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withEmployeeEOB(Component) {
	return (props) => {

		const { explanationOfBenefits, employee } = useSelector(state => state.myEmployees)
		const dispatch = useDispatch()
		const employeeEOBSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			explanationOfBenefits,
			employee
		}
		const passThruProps = {...props, employeeEOBSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withEmployeeEOB