import React from "react";
import {Box, Button, ButtonGroup, Modal, Paper, TextField, Typography} from '@mui/material'
import PageHeader from "../../components/page-header";
import Admins from "./admins";
import DeductionRecipients from "./deduction-recipients";

const MySettings = (props) => {

    return (<>
        <Box>
            <PageHeader>
                <Typography variant="h5">Settings</Typography>
            </PageHeader>

            <Admins />

            <DeductionRecipients />
        </Box>
    </>)
}

export default MySettings