import constants from "./constants";

const initState = {
    createCompanyStatus: "idle",
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case `${constants.CREATE_COMPANY}/pending`: {
            return {
                ...state,
                createCompanyStatus: "pending",
            };
        }
        case `${constants.CREATE_COMPANY}/fulfilled`: {
            return {
                ...state,
                createCompanyStatus: "success",
            };
        }
        case `${constants.CREATE_COMPANY}/rejected`: {
            return {
                ...state,
                createCompanyStatus: "error",
            };
        }

        case constants.RESET_CREATE_COMPANY: {
            return {
                ...state,
                createCompanyStatus: 'idle'
            }
        }
        default:
            return { ...state };
    }
};

export default reducer;
