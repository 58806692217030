import React from 'react'
import PageHeader from "../../../../components/page-header";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  ButtonGroup
} from '@mui/material'
import styled from '@mui/material/styles/styled'
import withEmployeeActive from '../../sdk/withEmployeeActive'
import SaveSnackbar from '../../../../components/save-snackbar';
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box';

const StyledBox = styled(Box)(({theme}) => ({
    width: "100%",
    padding: "24px 20px 20px 20px",
    borderRadius: "24px 24px 0px 0px",
    position: 'absolute',
    bottom: 0,
    left: 0,
    flexDirection: "column",
    alignItems: "center",
    background: "var(--Neutral-White, #FFF)",
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        bottom: 'unset',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: "flex",
        width: "600px",
        padding: "var(--spacing-12, 48px)",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "24px",
        gap: 24,
        flexShrink: 0,
    }
}))

const StyledEmployee = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& h5': {
    textWrap: 'nowrap',
  }
}))



const EmployeeHeader = (props) => { 
  
  const [initialLoad, setInitialLoad] = React.useState(false) 
  const [ show, setShow ] = React.useState(false)
  const [ isActive, setIsActive ] = React.useState(false)
  const [ showConfirmation, setShowConfirmation ] = React.useState(false)

  const { 
    employeeActiveSDK: {
      employee,
      toggleEmployeeStatus,
      loadingActiveStatus
    } 
  } = props
  
  const {
    status,
    controlType
  } = employee
  const isAutomated = controlType === 'automated' ? true : false

  const toggleStatus = () => {
    if(isActive) setShowConfirmation(true)
    else toggleEmployeeStatus(employee.id, !isActive)
  } 

  const onCancelTermination = () => setShowConfirmation(false)

  const onConfirmTermination = () => {
    toggleEmployeeStatus(employee.id, !isActive)
    setShowConfirmation(false)
  }

  React.useEffect(() => { 
    setIsActive(status === 'active')
  }, [status])

  React.useEffect(() => {
    if(!initialLoad) { return setInitialLoad(true) }
    if(loadingActiveStatus === 'fulfilled' || loadingActiveStatus === 'rejected') {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [loadingActiveStatus])

  const isROGhost = sessionStorage.getItem('ghosting-role') === 'read-only'

  return (
    <>
    <PageHeader>
        <StyledEmployee>
          <Typography variant="h5">Employee Details</Typography>
        </StyledEmployee>
        <FormGroup>
          <FormControlLabel 
            control={
              <Switch 
                checked={isActive} 
                onChange={toggleStatus}
              />
            } 
            label="Active" 
            disabled={isAutomated || isROGhost}
          />
        </FormGroup> 
      </PageHeader> 
      <SaveSnackbar 
          open={show} 
          setOpen={() => setShow(false)} 
          statusMessages={
            {
              success: `${employee.firstName} is was updated`,
              error: `Failed to update ${employee.firstName}`
            }
          }
          severity={loadingActiveStatus === 'fulfilled' ? "success" : loadingActiveStatus === 'rejected' ? "error": ''}
        />
      <Modal 
        open={showConfirmation}
      >
        <StyledBox>
          <Typography variant="h6">Please Confirm Termination</Typography>
          <Typography variant="body1">Accidental termination of employee benefits will require the employee to rebuild their account upon reactivation.</Typography>
          <ButtonGroup>
            <Button onClick={onCancelTermination}>Cancel</Button>
            <Button variant="contained" onClick={onConfirmTermination}>Confirm</Button>
          </ButtonGroup>
        </StyledBox>
      </Modal>
    </>
  )
}

export default withEmployeeActive(EmployeeHeader)