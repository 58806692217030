import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import styled from "@mui/material/styles/styled";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import withGhosting from "../sdk/withGhosting";


const StyledGhosting = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '1035px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    width: '320px',
  }
}))

const FILTERED_COMPANIES = require('./filtered-companies.json')
const GHOSTING_PERMISSION = 'portal:ghosting:*'
const GHOSTING_RO_PERMISSION = 'portal:admin:read'
const GHOSTING_ADMIN_PERMISSION = 'portal:admin:*'

const filterCompanies = (options) => {
  let set = new Set([...FILTERED_COMPANIES.map(c => c.id)])
  return options.filter(option => !set.has(option.id))
}

const Ghosting = (props) => {
  const { 
    ghostingCompanies, 
    findMyGhostingCompanies, 
    updateMyGhostingCompany,
    ghostingStatus,  
    findAdvancesSummary,
    findLatestAdvances
  } = props.ghostingSDK

  let sessionGhost = {}
  let sessionGhostStr = sessionStorage.getItem('ghosting-as')
  if(sessionGhostStr !== "undefined") sessionGhost = JSON.parse(sessionGhostStr)
  const [companyId, setCompanyId] = React.useState(sessionGhost?.id || '');
  const [ isGhost, setIsGhost ] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const {
        permissions,
      } = jwtDecode(token);
      setIsGhost(permissions.includes(GHOSTING_PERMISSION))
      if(permissions.includes(GHOSTING_RO_PERMISSION)){
        sessionStorage.setItem('ghosting-role', 'read-only')
        findMyGhostingCompanies()
      } else if(permissions.includes(GHOSTING_ADMIN_PERMISSION)){
        sessionStorage.setItem('ghosting-role', 'admin')
        findMyGhostingCompanies()
      }
      else {
        sessionStorage.removeItem('ghosting-ro')
        sessionStorage.removeItem('ghosting-as')
      }
    })
  }, [])

  React.useEffect(() => {
    let ghostingAs
    try {
      ghostingAs = JSON.parse(sessionStorage.getItem('ghosting-as'))
    } catch(e) {
      ghostingAs = { id: '' }
    }
    if(ghostingAs) {
      setCompanyId(ghostingAs.id)
    }
  },[])

  let active = ghostingCompanies.find(company => company.active)
  React.useEffect(() => {
    if(ghostingStatus !== 'idle') return 
    if(active) {
      setCompanyId(active.id)
      sessionStorage.setItem('ghosting-as', JSON.stringify(active))
      findAdvancesSummary()
      findLatestAdvances()
    }
  }, [active])

  const getCompanyFromSelection = selection => ghostingCompanies.find(company => company.id === selection)

  const onChange = (event) => {
    let company = getCompanyFromSelection(event.target.value)
    updateMyGhostingCompany(company)
  }

  if(!isGhost) return null
  
  let displayCompanies = filterCompanies(ghostingCompanies)

  return (
    <StyledGhosting>
    <FormControl fullWidth>
      <InputLabel id="ghosting-select-label">Ghosting</InputLabel>
      <Select
        labelId="ghosting-select-label"
        id="ghosting-select"
        value={companyId}
        label="Ghosting"
        onChange={onChange}
        disabled={displayCompanies.length === 0}
      >
        {displayCompanies.map((company) => (<MenuItem key={Math.random()+company.id} value={company.id}>{company.company_name}</MenuItem>))}
    
      </Select>
    </FormControl>
    </StyledGhosting>
  );
};

export default withGhosting(Ghosting);
