export default {
    company:
    {
        company_name: 'CHRIS Co.',
        email: 'chris@test.com',
        contact_phone: '123-456-7890',
        unique_code: 'GP-TEST',
        contact_first_name: 'Chris',
        contact_last_name: 'Dimmy',
        contact_job_title: 'Boss'
    },
    company_ruleset: {
        number_of_working_days: 365,
        number_of_pay_periods_per_year: 24,
        max_eligible_amount: 875.00,
        earnings_percentage_limit: 0.50,
        max_hours_per_day: 10,
        directory_control_type: 'automated'
    },
    company_safeguards: {
        absolute_max_eligible_amount_per_pay_period: 800.00,
        max_hours_per_day: 10.00,
        earnings_percentage_limit: 0.50,
        work_days_in_week: 5
    },
    deduction_recipient: {
        email: 'hr@chris.com'
    }
}