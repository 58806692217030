import React from "react";
import {Box, Button, ButtonGroup, Modal, Paper, TextField, Typography} from '@mui/material'
import PageHeader from "../../components/page-header";
import withStablAdmin from './sdk/withStablAdmin'
import {StyledHeader} from "./styled-components";
import SaveSnackbar from "../../components/save-snackbar";

import baseData from './data'

const StablAdmin = (props) => {

    const {
        stablAdminSDK: {
            api: {
                createCompany,
                resetCreateCompany
            },
            createCompanyStatus
        }
    } = props

    const [showSnackbar, setShowSnackbar] = React.useState({
        show: false,
        severity: 'success'
    })
    const jsonRef = React.useRef(null)

    React.useEffect(() => {
        if (createCompanyStatus === 'success') {
            setShowSnackbar({
                show: true,
                severity: 'success'
            })
            resetCreateCompany()
        }
    }, [createCompanyStatus])

    const save = () => {
        try {
            const payload = JSON.parse(jsonRef.current.value)
            createCompany(payload)
        } catch (e) {
            console.log('e', e)
        }
    }

    const baseJson = JSON.stringify(baseData, null, 2)

    return (<>
        <Box>
            <PageHeader>
                <Typography variant="h5">Stabl Admin</Typography>
            </PageHeader>

            <StyledHeader>
                <Typography
                    sx={{marginBottom: '16px'}}
                    variant="h6"
                    color='primary'
                >
                    Create Company
                </Typography>

                <Button onClick={save} variant={"contained"}>
                    Save
                </Button>
            </StyledHeader>

            <TextField
                inputRef={jsonRef}
                fullWidth
                label="Company Data"
                variant="outlined"
                rows={25}
                multiline={true}
                defaultValue={baseJson}
            />

        </Box>

        <SaveSnackbar
            open={showSnackbar.show}
            setOpen={(show) => {
                setShowSnackbar({...showSnackbar, show})
            }}
            statusMessages={{
                success: 'Company has been created.',
                error: "An error has occurred. Please try again later."
            }}
            severity={showSnackbar.severity}
        />
    </>)
}

export default withStablAdmin(StablAdmin)