import styled from "@mui/material/styles/styled";

const StyledDiv = styled('div')(({theme}) => ({
  marginTop: '36px'
}))

function FinancialCoaching(){
  return (
    <StyledDiv>Financial Coaching Placeholder</StyledDiv>
  )
}

export default FinancialCoaching