import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withSafeguards(Component) {
	return (props) => {

		const safeguards = useSelector(state => state.mySafeguards.safeguards)
		const loadingStatus = useSelector(state => state.mySafeguards.safeguardStatus)
		const dispatch = useDispatch()
		const safeguardsSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			safeguards,
      loadingStatus,
			patchStatus: useSelector(state => state.mySafeguards.patchStatus)
		}
		const passThruProps = {...props, safeguardsSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withSafeguards