const tabs = {
	MuiTabs: {
		styleOverrides: {
			indicator: {
				display: 'none'
			}
		}
	},
	MuiTab: {
		styleOverrides: {
			root: {
				textTransform: 'capitalize',
				marginBottom: '16px',
				borderRadius: '10px',
				padding: '8px 16px',
				alignItems: 'flex-start',
			},
		}
	}
}

export default tabs