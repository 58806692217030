import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withCreateEmployee(Component) {
	return (props) => {

		const createdEmployee = useSelector(state => state.myEmployees.createdEmployee)
		const loadingStatus = useSelector(state => state.myEmployees.createEmployeeStatus)
		const errorMessage = useSelector(state => state.myEmployees.createEmployeeIssue)
		const createdEmployeeSafeguards = useSelector(state => state.myEmployees.employeeSafeguards)
		const createdEmployeeSafegaurdsStatus = useSelector(state => state.myEmployees.employeeSafeguardsStatus)
		const dispatch = useDispatch()
		const employeeSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			createdEmployee,
			loadingStatus,
			createdEmployeeSafeguards,
			createdEmployeeSafegaurdsStatus,
			errorMessage,
		}
		const passThruProps = {...props, employeeSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withCreateEmployee