const modal = {
    MuiModal: {
        styleOverrides: {
            root: {
                maxWidth: '1600px'
            },
        }
    }
}

export default modal