import React from 'react'
import PageHeader from "../../../components/page-header";
import {
  Typography,
} from '@mui/material'
import styled from '@mui/material/styles/styled'

const StyledEmployee = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start'
}))



const EmployeeHeader = () => { 
  return (
    <PageHeader>
        <StyledEmployee>
          <Typography variant="h5">Add Employee</Typography>
        </StyledEmployee>
      </PageHeader> 
  )
}

export default EmployeeHeader