import Skeleton from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled'

const StyledWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}))


const Skeletonized = () => {
  return (
    <StyledWrapper><Skeleton variant="rectangular" width={'100%'} height={65} /></StyledWrapper>
  )
}

export default Skeletonized;