import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withEmployee(Component) {
	return (props) => {

		const employees = useSelector(state => {
			const { 
				myEmployees: {
					employees
				}
			} = state
			return employees
		})


		const directoryControlType = useSelector(state => {
			return state.myEmployees.companyRules.directoryControlType
		})

		const dispatch = useDispatch()
		const employeesSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			employees,
			directoryControlType
		}
		const passThruProps = {...props, employeesSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withEmployee