import React from "react";
import { useNavigate } from "react-router-dom";
import withEmployeeAdvances from "../../sdk/withEmployeeAdvances";
import { useLocation } from "react-router-dom";
import { Typography, Paper, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "@mui/material/styles/styled";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const makeMoneyPretty = (dollars) =>
  dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const dateFormat = (date) => new Date(date).toLocaleDateString();
const toDayStr = (day) => moment(day, "DD").format("Do");

const prettyDateStr = (date) => {
  if(!date) return ''
  const [year, month, day] = date.split("-");
  return `${moment(month, "MM").format("MMM")} ${toDayStr(day)}`;
};

const StyledStatusCell = styled(TableCell)(({ theme }) => ({
  textTransform: "capitalize",
  width: "100px",
  maxWidth: "100px",
  borderRight: "1px solid rgba(224, 224, 224, 1) !important",
  display: "none",
  [theme.breakpoints.up("xs")]: {
    display: "table-cell",
  },
}));

const StyledCreatedCell = styled(TableCell)(({ theme }) => ({
  // width: '100px',
  // maxWidth: '100px'
}));

const StyledIDCell = styled(TableCell)(({ theme }) => ({
  width: "120px",
  maxWidth: "120px",
  borderRight: "1px solid rgba(224, 224, 224, 1) !important",
}));

const StyledMoneyCell = styled(TableCell)(({ theme }) => ({
  width: "155px",
  borderRight: "1px solid rgba(224, 224, 224, 1) !important",
}));

const StyledSectionHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  [theme.breakpoints.up("xs")]: {
    alignItems: "flex-end",
  },
}));

const StyledButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& p": {
    marginRight: "8px",
    display: "none",
  },
  [theme.breakpoints.up("xs")]: {
    "& p": {
      display: "block",
    },
  },
}));

const EndStyledButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& p": {
    marginRight: "8px",
  },
}));

const PageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: '8px'
}))

const MobileTable = styled(Table)(({ theme }) => ({
  minWidth: "100%",
}));

const statusToHuman = (status) => {
  switch (status.toLowerCase()) {
    case "approved":
    case "pending":
      return "Pending";
    case "manual":
    case "paid_out":
      return "Paid Out";
    case "denied":
      return "Ineligible";
    default:
      return status;
  }
};

const PreviousAdvances = (props) => {
  const { pathname, search } = useLocation();
  const [,root, view, pageEmployeeID, ...rest] = pathname.split("/");
  
  const navigate = useNavigate();
  const query = search
    .replace("?", "")
    .split("&")
    .reduce((acc, pair) => {
      const [key, value] = pair.split("=");
      return { ...acc, [key]: value };
    }, {});

  const {
    employeeSDK: {
      employeeAdvances: { payPeriodStart, payPeriodEnd, data },
      findEmployeeAdvances,
    },
  } = props;

  React.useEffect(() => {
    findEmployeeAdvances(pageEmployeeID, query.previousPayperiod);
  }, [query.previousPayperiod]);

  const summary = makeMoneyPretty(
    data.reduce((acc, advance) => advance.amount.cents + acc, 0) / 100
  );

  const makeNextLocation = () => `/${root}/${view}/${pageEmployeeID}/${rest.join('/')}?previousPayperiod=${parseInt(query.previousPayperiod) + 1}`
  const makePreviousLocation = () => `/${root}/${view}/${pageEmployeeID}/${rest.join('/')}?previousPayperiod=${parseInt(query.previousPayperiod) - 1}`
  const toCurrent = () => `/${root}/${view}/${pageEmployeeID}`

  if(!payPeriodEnd || !payPeriodStart){
    return (
      <Box>
        <StyledSectionHeader>
          <Typography variant="h6" color="primary">You have reach the historical end of advances</Typography>
        </StyledSectionHeader>    
        <PageBox>
          <EndStyledButtonBox>
            <IconButton
              onClick={() => navigate(makePreviousLocation())}
            >
              <ArrowBackIosIcon />
              <Typography variant="body1">Back</Typography>
            </IconButton>
          </EndStyledButtonBox>
        </PageBox>      
      </Box>
    )

  }
  return (
    <Box>
      <StyledSectionHeader>
        <Box>
          <Typography variant="h6" color="primary">
            Current Pay Period Advances
          </Typography>
          <Typography color="primary">
            Pay Period: {prettyDateStr(payPeriodStart)} to{" "}
            {prettyDateStr(payPeriodEnd)}
          </Typography>
          <Typography sx={{ marginBottom: "16px" }} color="primary">
            Total: ${summary}
          </Typography>
        </Box>
        </StyledSectionHeader>
        
      <TableContainer component={Paper}>
        <MobileTable>
          <TableHead>
            <TableRow>
              <StyledIDCell>StablPay ID</StyledIDCell>
              <StyledMoneyCell>Advance Amount</StyledMoneyCell>
              <StyledStatusCell>Status</StyledStatusCell>
              <StyledCreatedCell align="right">Created</StyledCreatedCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>No advances taken</TableCell>
              </TableRow>
            )}
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledIDCell>{row.id}</StyledIDCell>
                <StyledMoneyCell>
                  ${makeMoneyPretty(row.amount.cents / 100)}
                </StyledMoneyCell>
                <StyledStatusCell>{statusToHuman(row.status)}</StyledStatusCell>
                <StyledCreatedCell align="right">
                  {dateFormat(row.created_at)}
                </StyledCreatedCell>
              </TableRow>
            ))}
          </TableBody>
        </MobileTable>
      </TableContainer>
      <PageBox>
          { query.previousPayperiod > 1 ? (
          <StyledButtonBox>
            <IconButton
              onClick={() => navigate(makePreviousLocation())}
            >
              <ArrowBackIosIcon />
              <Typography variant="body1">{query.previousPayperiod} Pay periods ago </Typography>
            </IconButton>
          </StyledButtonBox>
          ) : (
            <StyledButtonBox>
            <IconButton
              onClick={() => navigate(toCurrent())}
            >
              <ArrowBackIosIcon />
              <Typography variant="body1">Back to Employee Details </Typography>
            </IconButton>
          </StyledButtonBox>

          )}
          <StyledButtonBox>
            <IconButton
              onClick={() => navigate(makeNextLocation())}
            >
              <Typography variant="body1">{parseInt(query.previousPayperiod) + 1} Pay periods ago </Typography>
              <ArrowForwardIosIcon />
            </IconButton>
          </StyledButtonBox>
        </PageBox>
    </Box>
  );
};

export default withEmployeeAdvances(PreviousAdvances);
