import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

function withAuth(Component) { return (props) => {
	const {
		isAuthenticated,
	} = useAuth0();

	if (isAuthenticated) {
		return (
			<Component {...props} />
		)
	}
	else {
		return (
			<Navigate to="/login" replace />
		)
	}
}}

export default withAuth