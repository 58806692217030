import Skeleton from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled'
import {
  ButtonGroup,
  Button,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Paper,
  Switch,
} from '@mui/material'

const StyledRow = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '32px'
}))

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: "16px 0"
}))

const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}))


const Skeletonized = () => {
  return (
    <Box>
      <Typography variant="h6">Employee Information</Typography>
      <StyledHeader>
        <div>
        <Skeleton variant="rectangular" width={'100%'} height={40} />
        <Skeleton variant="rectangular" width={'100%'} height={20} />
       </div>
      </StyledHeader>
      <StyledForm>
        <Paper sx={{padding: '16px'}}>
          <StyledRow>
            <Skeleton variant="rectangular" width={'50%'} height={40} /> 
            <Skeleton variant="rectangular" width={'50%'} height={40} />
          </StyledRow>
          <StyledRow>
          <Skeleton variant="rectangular" width={'30%'} height={40} />
          <Skeleton variant="rectangular" width={'30%'} height={40} />
          <Skeleton variant="rectangular" width={'30%'} height={40} />
          </StyledRow>
          <StyledRow>
          <Skeleton variant="rectangular" width={'30%'} height={40} />
          <Skeleton variant="rectangular" width={'30%'} height={40} />
          <Skeleton variant="rectangular" width={'30%'} height={40} />
          </StyledRow>
          <ButtonGroup sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Button
              disabled
            >
              Reset
            </Button>
            <Button 
              variant="contained"
              disabled
            >
              Save
            </Button>
          </ButtonGroup>
        </Paper>
      </StyledForm>
    </Box>
  )
}

export default Skeletonized;