import React from 'react';
import {
    Route,
    Routes,
} from 'react-router-dom'
import Dashboard from '../pages/dashboard/index.jsx'
import MyEmployees from '../pages/my-employees/index.jsx'
import Login from '../pages/login/index.jsx'
import Employee from '../pages/my-employees/employee/index.jsx'
import AddEmployee from '../pages/my-employees/add/index.jsx'
import SafeGuards from '../pages/safeguards/index.jsx'
import withAuth from "../hoc/withAuth.jsx";
import HistoricalAdvances from '../pages/my-employees/employee/historical-advances/index.jsx';
import MySettings from '../pages/my-settings/index.jsx'
import FinancialCoaching from '../pages/my-employees/financial-coaching/index.jsx';
import StablAdmin from "../pages/stabl-admin";

const AuthDashboard = withAuth(Dashboard)
const AuthMyEmployees = withAuth(MyEmployees)
const AuthViewEmployee = withAuth(Employee)
const AuthSafeGuards = withAuth(SafeGuards)
const AuthAddEmployee = withAuth(AddEmployee)
const AuthHistoricalAdvances = withAuth(HistoricalAdvances)
const AuthFinancialCoaching = withAuth(FinancialCoaching)
const AuthMySettings = withAuth(MySettings)
const AuthStablAdmin = withAuth(StablAdmin)

function Router() {
    return (
        <Routes>
            <Route path="/" element={<AuthDashboard/>}/>
            <Route path="/dashboard" element={<AuthDashboard/>}/>
            <Route path='/my-employees/add' element={<AuthAddEmployee/>}/>
      			<Route path="/my-employees/view/:id/financial-coaching" element={<AuthFinancialCoaching />} />
            <Route path="/my-employees/view/:id/previous-advances" element={<AuthHistoricalAdvances/>}/>
            <Route path='/my-employees/view/:id' element={<AuthViewEmployee/>}/>
            <Route path="/my-employees/view/:id/previous-advances" element={<AuthHistoricalAdvances/>}/>
            <Route path="/my-employees" element={<AuthMyEmployees/>}/>
            <Route path="/my-company-safeguards" element={<AuthSafeGuards/>}/>
            <Route path="/my-settings" element={<MySettings/>}/>
            <Route path="/stabl-admin" element={<StablAdmin/>}/>
            <Route path="/login" element={<Login/>}/>
        </Routes>
    )
}

export default Router
