import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withResetMFA(Component) {
	return (props) => {

		const employeeMfaMethods = useSelector(state => state.myEmployees.employeeMfaMethods)
		const employeeMfaMethodsStatus = useSelector(state => state.myEmployees.employeeMfaMethodsStatus)
		const createOtpStatus = useSelector(state => state.myEmployees.createOtpStatus)
		const createOtpError = useSelector(state => state.myEmployees.createOtpError)
		const resetMfaMethodStatus = useSelector(state => state.myEmployees.resetMfaMethodStatus)
		const resetMfaMethodError = useSelector(state => state.myEmployees.resetMfaMethodError)
		const dispatch = useDispatch()
		
    const mfaResetSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			employeeMfaMethods,
			employeeMfaMethodsStatus,
			createOtpStatus,
			createOtpError,
			resetMfaMethodStatus,
			resetMfaMethodError,
		}
		const passThruProps = {...props, mfaResetSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withResetMFA