const menuItem = {
	MuiMenuItem: {
		styleOverrides: {
			root: {
				textTransform: 'capitalize',
			},
		}
	},
}

export default menuItem