import React from "react";
import {Divider, Icon, Toolbar, Typography} from "@mui/material";
import {List, ListItem} from "@mui/material";
import {
    AppBar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Drawer,
    IconButton,
} from "@mui/material";
import EmployeesIcon from "@mui/icons-material/PeopleOutlineSharp";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {useAuth0} from "@auth0/auth0-react";
import {useLocation} from "react-router-dom";
import HomeIcon from "../components/icons/home";
import SafeguardIcon from "../components/icons/safeguard";
import LogoutIcon from "../components/icons/logout";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {jwtDecode} from "jwt-decode";


const DRAWER_WIDTH = "280px";

const StyledContainer = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "space-between",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    width: 0,
    [theme.breakpoints.up("md")]: {
        width: DRAWER_WIDTH,
        minWidth: DRAWER_WIDTH,
    },
}));

const StyledTopContainer = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 180px)",
    justifyContent: "space-between",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    width: DRAWER_WIDTH,
}));


const StyledLogo = styled("div")(({theme}) => ({
    display: "flex",
    padding: "24px",
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
    display: "flex",
    padding: "16px var(--16, 16px)",
    justifyContent: "space-between",
    alignItems: "flex-start",
    // gap: "var(--24, 24px)",
}));

const DrawerPageNav = styled("div")(({theme}) => ({
    display: "block",
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
}));

const FullPageNav = styled("div")(({theme}) => ({
    display: "none",
    [theme.breakpoints.up("md")]: {
        display: "block",
    },
}));


const PageNav = ({root, navigate, logoutWithRedirect, isStablAdmin}) => (
    <>
        <Toolbar>
            <StyledLogo>
                <img
                    src="/stabl-pay-logo.svg"
                    alt="StablPay Logo"
                    width="100%"
                    height="100%"
                />
            </StyledLogo>
        </Toolbar>
        <Divider/>
        <StyledTopContainer>
            <List>
                <ListItem>
                    <ListItemButton
                        selected={root === "" || root === "dashboard"}
                        onClick={() => navigate("/")}
                    >
                        <ListItemIcon>
                            <HomeIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="h6" color="primary">
                                    Dashboard
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton
                        selected={root === "my-employees"}
                        onClick={() => navigate("/my-employees")}
                    >
                        <ListItemIcon>
                            <EmployeesIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="h6" color="primary">
                                    Employees
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton
                        selected={root === "my-company-safeguards"}
                        onClick={() => navigate("/my-company-safeguards")}
                    >
                        <ListItemIcon>
                            <SafeguardIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="h6" color="primary">
                                    Safeguards
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </ListItem>
                {isStablAdmin && (<ListItem>
                    <ListItemButton
                        selected={root === "stabl-admin"}
                        onClick={() => navigate("/stabl-admin")}
                    >
                        <ListItemIcon>
                            <SafeguardIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="h6" color="primary">
                                    Stabl Admin
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </ListItem>)}
            </List>
            <List>
                <ListItem>
                    <ListItemButton
                        selected={root === "my-settings"}
                        onClick={() => navigate("/my-settings")}
                    >
                        <ListItemIcon>
                            <SettingsOutlinedIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="h6" color="primary">
                                    Settings
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </StyledTopContainer>
        <div>
            <Divider/>
            <List>
                <ListItem>
                    <ListItemButton onClick={logoutWithRedirect}>
                        <ListItemIcon>
                            <LogoutIcon color="primary"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="h6" color="primary">
                                    Logout
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    </>
);

const STABL_ADMIN_PERMISSION = 'stabl:admin:*'

const SideNav = () => {
    const [open, setOpen] = React.useState(false);
    const [isStablAdmin, setIsStablAdmin] = React.useState(false);
    const navigate = useNavigate();
    const {getAccessTokenSilently} = useAuth0();

    React.useEffect(() => {
        getAccessTokenSilently().then((token) => {
            const {
                permissions,
            } = jwtDecode(token);

            setIsStablAdmin(permissions.includes(STABL_ADMIN_PERMISSION))
        })
    }, [])

    const {logout} = useAuth0();
    const location = useLocation();
    const logoutWithRedirect = () => {
        window.localStorage.removeItem("redirectPath");
        window.sessionStorage.clear()
        logout({
            logoutParams: {
                returnTo: location.origin,
            },
        });
    };

    const {pathname} = location;
    const [root] = pathname.replace("/", "").split("/");

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    display: {xs: "block"},
                    // ml: { sm: DRAWER_WIDTH },
                    // background: '#FFF',
                    boxShadow: "none",
                    // borderBottom: '2px solid #ebecec',
                    // top: 0,
                    // right: 0,
                    // zIndex: 1000,
                    // backgroundColor: 'white'
                }}
            >
                <StyledToolbar>
                    <img src={"/stabl-pay-logo.svg"} alt="Stabl Pay"/>
                    <IconButton
                        color="secondary"
                        aria-label="open drawer"
                        edge="end"
                        onClick={() => setOpen(true)}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                </StyledToolbar>
            </AppBar>
            <StyledContainer>
                <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
                    <DrawerPageNav>
                        <PageNav
                            root={root}
                            navigate={navigate}
                            logoutWithRedirect={logoutWithRedirect}
                            isStablAdmin={isStablAdmin}
                        />
                    </DrawerPageNav>
                </Drawer>
                <FullPageNav>
                    <PageNav
                        root={root}
                        navigate={navigate}
                        logoutWithRedirect={logoutWithRedirect}
                        isStablAdmin={isStablAdmin}
                    />
                </FullPageNav>
            </StyledContainer>
        </>
    );
};

export default SideNav;
