import { createAsyncThunk } from '@reduxjs/toolkit'
import constants from '../redux/constants'
import store from '../../../store'

const { dispatch } = store

const api = client => ({
    createCompany: async (payload) => {
        const create = createAsyncThunk(
            constants.CREATE_COMPANY,
            async () => {
                const response = await client.post(`/api/v2/admin/company`, payload)
                return response.data
            }
        )
        dispatch(create())
    },
    resetCreateCompany: () => {
        dispatch({
            type: constants.RESET_CREATE_COMPANY
        })
    },
})

export default api