import React from 'react'
import {Box,} from '@mui/material'
import EmployeeHeader from './employee-header'
import EmployeeDetails from './employee-details'
import EmployeeAdvances from './employee-advances'
import EmployeeSafeguards from './employee-safeguards'
import EOB from './explanation-of-benefits'
import EmployeeMfa from "./employee-mfa";
import InternalNotes from "./internal-notes";

const Employee = props => {
    const isGhostRO = sessionStorage.getItem('ghosting-role') === 'read-only'
    return (
        <Box>
            <EmployeeHeader/>
            <EmployeeDetails/>
            <EmployeeMfa/>
            {isGhostRO && <EOB/>}
            <EmployeeSafeguards/>
            <InternalNotes />
            <EmployeeAdvances/>
        </Box>
    )
}

export default Employee