import constants from './constants'

const initState = {
  employeeStatus: 'idle',
  employeesStatus: 'idle',
  employeeAdvancesStatus: 'idle',
  employeeSafeguardsStatus: 'idle',
  createEmployeeStatus: 'idle',
  employeeActiveStatus: 'idle',
  employeeUpdateStatus: 'idle',
  supportEscalationStatus: 'idle',
  employees: {
    all: []
  },
  employee: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    externalEmployeeId: '',
    employmentType: '',
    updated_at: '',
    position: '',
    hourlyWage: '',
    salary: '',
    status: ''
  },
  employeeAdvances: {
    payPeriodStart: '',
    payPeriodEnd: '',
    data: []
  },
  employeeSafeguards: {
    maxAdvance: 0,
    maxHours: 0,
    maxPercentage: 0,
    companyHardLimits: {
      maxAdvance: 0,
      maxHours: 0,
      maxPercentage: 0
    }
  },
  createdEmployee: null,
  companyRules: {
    directoryControlType: 'automated'
  },
  explanationOfBenefits: {
    type: 'hourly',
    shifts: [],
    rate: 0,
    personalSafeguard: 0,
    totalEarned: 0,
    grossEligibility: 0,
    eligibility: {
      netAmount: 0,
      reason: 'Total Earned'
    },
    advancesTaken: 0,
    advancingStructure: {
      workDaysInYear: 0,
      eligibleDaysInPayPeriod: 0,
      dailyRate: 0
    }
  },
  employeeMfaMethods: [],
  employeeMfaMethodsStatus: 'idle',
  resetMfaMethodStatus: 'idle',
  resetMfaMethodError: null,
  createOtpStatus: 'idle',
  createOtpError: null,
  sendWelcomeEmailStatus: 'idle',
  latestInternalNote: '',
  createInternalNotesStatus: 'idle',
  createInternalNotesError: null,
  createEmployeeIssue: '',
  updateEmployeeIssue: ''
}

const reducer = (state= initState, action) => {
  switch(action.type) {
    case `${constants.FIND_ALL_EMPLOYEES}/pending`: {
      return {
        ...state,
        employeesStatus: 'pending',
        employees: {
          ...state.employees
        }
      }
    }
    case `${constants.FIND_ALL_EMPLOYEES}/fulfilled`: {
      return {
        ...state,
        employeesStatus: 'fulfilled',
        employees: {
          limit: action.payload.limit,
          offset: action.payload.offset,
          total_count: action.payload.total_count,
          all: [
            ...state.employees.all,
            ...action.payload.data.map(employee => ({
              id: employee.id,
              firstName: employee.first_name,
              lastName: employee.last_name,
              email: employee.email,
              phone: employee.phone,
              externalEmployeeId: employee.external_employee_id,
              employmentType: employee.employment_type,
              updated_at: employee.updated_at,
              status: employee.status,
              position: employee.position,
              hourlyWage: employee.hourly_wage,
              salary: employee.salary,
            }))
          ]
        }
      }
    }
    case `${constants.FIND_EMPLOYEES_PAGE}/fulfilled`: {
      return {
        ...state,
        employeesStatus: 'fulfilled',
        employees: {
          limit: action.payload.limit,
          offset: action.payload.offset,
          total_count: action.payload.total_count,
          all: [
            ...action.payload.data.map(employee => ({
              id: employee.id,
              firstName: employee.first_name,
              lastName: employee.last_name,
              email: employee.email,
              phone: employee.phone,
              externalEmployeeId: employee.external_employee_id,
              employmentType: employee.employment_type,
              updated_at: employee.updated_at,
              status: employee.status,
              position: employee.position,
              hourlyWage: employee.hourly_wage,
              salary: employee.salary,
            }))
          ]
        }
      }
    }
    case `${constants.FIND_ONE_EMPLOYEE}/pending`: {
      return {
        ...state,
        employeeStatus: 'pending',
        employee: {
          ...state.employee,
        }
      }
    }
    case `${constants.FIND_ONE_EMPLOYEE}/fulfilled`: {
      return {
        ...state,
        employeeStatus: 'fulfilled',
        employee: {
          id: action.payload.id,
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          email: action.payload.email,
          phone: action.payload.phone,
          externalEmployeeId: action.payload.external_employee_id,
          employmentType: action.payload.employment_type,
          updated_at: action.payload.updated_at,
          position: action.payload.position,
          hourlyWage: action.payload.hourly_wage,
          salary: action.payload.salary,
          status: action.payload.status,
          controlType: action.payload.control_type,
          payrollId: action.payload.payroll_id,
          pendingTermination: action.payload.pending_termination
        }
      }
    }
    case `${constants.FIND_EMPLOYEE_ADVANCES}/pending`: {
      return {
        ...state,
        employeeAdvancesStatus: 'pending',
        employeeAdvances: {
          ...state.employeeAdvances,
          data: []
        }
      }
    }
    case `${constants.FIND_EMPLOYEE_ADVANCES}/fulfilled`: {
      return {
        ...state,
        employeeAdvancesStatus: 'fulfilled',
        employeeAdvances: {
          payPeriodEnd: action.payload.pay_period_end,
          payPeriodStart: action.payload.pay_period_start,
          data: action.payload.data.sort((a,b) => b.created_at.localeCompare(a.created_at))
        }
      }
    }
    case `${constants.RESET_ALL_EMPLOYEES}`: {
      return {
        ...state,
        employeesStatus: 'idle',
        employees: {
          all: []
        }
      }
    }
    case `${constants.FIND_EMPLOYEE_SAFEGUARDS}/pending`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'pending',
        employeeSafeguards: {
          ...state.employeeSafeguards
        }
      }
    }
    case `${constants.FIND_EMPLOYEE_SAFEGUARDS}/fulfilled`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'fulfilled',
        employeeSafeguards: {
          maxAdvance: action.payload.absolute_max_eligible_amount_per_pay_period,
          maxHours: action.payload.max_hours_per_day,
          maxPercentage: action.payload.earnings_percentage_limit,
          companyHardLimits: {
            maxAdvance: action.payload.limits.absolute_max_eligible_amount_per_pay_period,
            maxHours: action.payload.limits.max_hours_per_day,
            maxPercentage: action.payload.limits.earnings_percentage_limit
          }
        }
      }
    }
    case `${constants.UPDATE_EMPLOYEE_SAFEGUARDS}/pending`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'pending'
      }
    }
    case `${constants.UPDATE_EMPLOYEE_SAFEGUARDS}/fulfilled`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'fulfilled',
        employeeSafeguards: {
          maxAdvance: action.payload.absolute_max_eligible_amount_per_pay_period,
          maxHours: action.payload.max_hours_per_day,
          maxPercentage: action.payload.earnings_percentage_limit,
          companyHardLimits: {
            maxAdvance: action.payload.limits.absolute_max_eligible_amount_per_pay_period,
            maxHours: action.payload.limits.max_hours_per_day,
            maxPercentage: action.payload.limits.earnings_percentage_limit
          }
        }
      }
    }
    case `${constants.UPDATE_EMPLOYEE_SAFEGUARDS}/rejected`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'rejected',
        createEmployeeIssue: action.payload.data.errors[0]?.message || 'Error updating employee'
      }
    }
    case `${constants.CREATE_EMPLOYEE}/pending`: {
      return {
        ...state,
        createEmployeeStatus: 'pending'
      }
    }
    case `${constants.CREATE_EMPLOYEE}/fulfilled`: {
      return {
        ...state,
        createEmployeeStatus: 'fulfilled',
        createdEmployee: {
          ...action.payload
        }
      }
    }
    case `${constants.CREATE_EMPLOYEE}/rejected`: {
      return {
        ...state,
        createEmployeeStatus: 'rejected',
        createEmployeeIssue: action.error?.message || 'Error creating employee'
      }
    }
    case `${constants.RESET_CREATED_EMPLOYEE}`: {
      return {
        ...state,
        createEmployeeStatus: 'idle',
        createdEmployee: null,
        createEmployeeIssue: ''
      }
    }
    case `${constants.RESET_EMPLOYEE_SAFEGUARDS}`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'idle',
        employeeSafeguards: {
          maxAdvance: 0,
          maxHours: 0,
          maxPercentage: 0,
          companyHardLimits: {
            maxAdvance: 0,
            maxHours: 0,
            maxPercentage: 0
          }
        }
      }
    }
    case `${constants.RESET_EMPLOYEE}`: {
      return {
        ...state,
        employeeStatus: 'idle',
        employee: {
          id: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          externalEmployeeId: '',
          employmentType: '',
          updated_at: '',
          position: '',
          hourlyWage: '',
          salary: '',
          status: ''
        }
      }
    }
    case `${constants.PATCH_EMPLOYEE_PENDING_TERMINATION}/pending`: {
      return {
        ...state,
        employeeActiveStatus: 'pending'
      }
    }
    case `${constants.PATCH_EMPLOYEE_PENDING_TERMINATION}/fulfilled`: {
      return {
        ...state,
        employeeActiveStatus: 'fulfilled',
        employee: {
          ...state.employee,
          pendingTermination: action.payload.pending_termination
        }
      }
    }
    case `${constants.PATCH_EMPLOYEE_PENDING_TERMINATION}/rejected`: {
      return {
        ...state,
        employeeActiveStatus: 'rejected'
      }
    }
    case `${constants.PATCH_EMPLOYEE_STATUS}/pending`: {
      return {
        ...state,
        employeeActiveStatus: 'pending'
      }
    }
    case `${constants.PATCH_EMPLOYEE_STATUS}/fulfilled`: {
      return {
        ...state,
        employeeActiveStatus: 'fulfilled',
        employee: {
          ...state.employee,
          status: action.payload.status
        }
      }
    }
    case `${constants.PATCH_EMPLOYEE_STATUS}/rejected`: {
      return {
        ...state,
        employeeActiveStatus: 'rejected'
      }
    }
    case `${constants.UPDATE_EMPLOYEE}/pending`: {
      return {
        ...state,
        employeeUpdateStatus: 'pending'
      }
    }
    case `${constants.UPDATE_EMPLOYEE}/fulfilled`: {
      return {
        ...state,
        employeeUpdateStatus: 'fulfilled',
        employee: {
          ...state.employee,
          ...action.payload
        },
        updateEmployeeIssue: action.error?.message || 'Error creating employee'
      }
    }
    case `${constants.UPDATE_EMPLOYEE}/rejected`: {
      return {
        ...state,
        employeeUpdateStatus: 'rejected'
      }
    }
    case `${constants.FIND_MY_COMPANY_RULESET}/pending`: {
      return state
    }
    case `${constants.FIND_MY_COMPANY_RULESET}/fulfilled`: {
      return {
        ...state,
        companyRules: {
          directoryControlType: action.payload.directory_control_type
        }
      }
    }
    case `${constants.FIND_MY_COMPANY_RULESET}/rejected`: {
      return state
    }
    case `${constants.GHOSTING_FIND_EMPLOYEE_EOB_HOURLY}/pending`: {
      return state
    }
    case `${constants.GHOSTING_FIND_EMPLOYEE_EOB_HOURLY}/fulfilled`: {
      return {
        ...state,
        explanationOfBenefits: {
          type: 'hourly',
          shifts: action.payload.shifts,
          rate: action.payload.rate,
          personalSafeguard: action.payload.personal_safeguard,
          totalEarned: action.payload.total_earned,
          grossEligibility: action.payload.gross_eligibility,
          eligibility: {
            netAmount: action.payload.eligibility.net_amount,
            reason: action.payload.eligibility.reason
          },
          advancesTaken: action.payload.advances_taken,
          advancingStructure: {}
        }
      }
    }
    case `${constants.GHOSTING_FIND_EMPLOYEE_EOB_HOURLY}/rejected`: {
      return state
    }
    case `${constants.GHOSTING_FIND_EMPLOYEE_EOB_SALARY}/pending`: {
      return state
    }
    case `${constants.GHOSTING_FIND_EMPLOYEE_EOB_SALARY}/fulfilled`: {
      return {
        ...state,
        explanationOfBenefits: {
          type: 'salary',
          shifts: [],
          rate: action.payload.rate,
          personalSafeguard: action.payload.personal_safeguard,
          totalEarned: action.payload.total_earned,
          grossEligibility: action.payload.gross_eligibility,
          eligibility: {
            netAmount: action.payload.eligibility.net_amount,
            reason: action.payload.eligibility.reason
          },
          advancesTaken: action.payload.advances_taken,
          advancingStructure: {
            workDaysInYear: action.payload.advancing_structure.work_days_in_year,
            eligibleDaysInPayPeriod: action.payload.advancing_structure.eligible_days_in_pay_period,
            dailyRate: action.payload.advancing_structure.daily_rate
          }
        }
      }
    }
    case `${constants.GHOSTING_FIND_EMPLOYEE_EOB_SALARY}/rejected`: {
      return state
    }
    case `${constants.GHOSTING_RESET_EOB}`: {
      return {
        ...state,
        explanationOfBenefits: {
          type: 'hourly',
          shifts: [],
          rate: 0,
          personalSafeguard: 0,
          totalEarned: 0,
          grossEligibility: 0,
          eligibility: {
            netAmount: 0,
            reason: 'Total Earned'
          },
          advancesTaken: 0,
          advancingStructure: {
            workDaysInYear: 0,
            eligibleDaysInPayPeriod: 0,
            dailyRate: 0
          }
        }
      }
    }
    case `${constants.GHOSTING_ESCALATE_TO_TIER_3}/pending`: {
      return {
        ...state,
        supportEscalationStatus: 'pending'
      }
    }
    case `${constants.GHOSTING_ESCALATE_TO_TIER_3}/fulfilled`: {
      return {
        ...state,
        supportEscalationStatus: 'fulfilled'
      }
    }
    case `${constants.GHOSTING_ESCALATE_TO_TIER_3}/rejected`: {
      return {
        ...state,
        supportEscalationStatus: 'rejected'
      }
    }
    case `${constants.GHOSTING_ESCALATE_TO_TIER_3_RESET}`: {
      return {
        ...state,
        supportEscalationStatus: 'idle'
      }
    }

    case `${constants.FIND_EMPLOYEE_MFA_METHODS}/fulfilled`: {
      return {
        ...state,
        employeeMfaMethods: action.payload,
        employeeMfaMethodsStatus: 'fulfilled'
      }
    }

    case `${constants.REMOVE_AUTH0_MFA_METHOD}/pending`: {
      return {
        ...state,
        resetMfaMethodStatus: 'loading',
        resetMfaMethodError: null
      }
    }
    case `${constants.REMOVE_AUTH0_MFA_METHOD}/fulfilled`: {
      return {
        ...state,
        employeeMfaMethods: action.payload,
        employeeMfaMethodsStatus: 'fulfilled',
        resetMfaMethodStatus: 'fulfilled',
        resetMfaMethodError: null,
      }
    }
    case `${constants.REMOVE_AUTH0_MFA_METHOD}/rejected`: {
      const error = action.payload.data?.errors ?
          action.payload.data?.errors[0]?.message :
          action.payload.data?.error
      return {
        ...state,
        resetMfaMethodStatus: 'error',
        resetMfaMethodError: error || action.payload || action.error?.message,
      }
    }

    // this will work while we only have one MFA method for now, but in the future if we have more, this will fail.
    case `${constants.RESET_EMPLOYEE_MFA_METHODS}`: {
      return {
        ...state,
        employeeMfaMethods: [],
        employeeMfaMethodsStatus: 'idle',
        resetMfaMethodStatus: 'idle',
        resetMfaMethodError: null,
        createOtpStatus: 'idle',
        createOtpError: null
      }
    }

    case `${constants.CREATE_EWA_EMPLOYEE_OTP}/pending`: {
      return {
        ...state,
        createOtpStatus: 'loading',
        createOtpError: null,
        resetMfaMethodError: null
      }
    }
    case `${constants.CREATE_EWA_EMPLOYEE_OTP}/fulfilled`: {
      return {
        ...state,
        createOtpStatus: 'fulfilled',
        createOtpError: null,
        resetMfaMethodError: null
      }
    }
    case `${constants.CREATE_EWA_EMPLOYEE_OTP}/rejected`: {
      return {
        ...state,
        createOtpStatus: 'rejected',
        createOtpError: action.payload || action.error?.message
      }
    }

    case `${constants.SEND_EWA_EMPLOYEE_WELCOME_EMAIL}/pending`: {
      return {
        ...state,
        sendWelcomeEmailStatus: 'loading',
      }
    }
    case `${constants.SEND_EWA_EMPLOYEE_WELCOME_EMAIL}/fulfilled`: {
      return {
        ...state,
        sendWelcomeEmailStatus: 'fulfilled',
      }
    }
    case `${constants.SEND_EWA_EMPLOYEE_WELCOME_EMAIL}/rejected`: {
      return {
        ...state,
        sendWelcomeEmailStatus: 'rejected',
      }
    }
    
    case `${constants.CREATE_INTERNAL_NOTES}/pending`: {
      return {
        ...state,
        createInternalNotesStatus: 'loading',
        createInternalNotesError: null
      }
    }
    case `${constants.CREATE_INTERNAL_NOTES}/fulfilled`: {
      return {
        ...state,
        latestInternalNote: {...action.payload},
        createInternalNotesStatus: 'fulfilled',
        createInternalNotesError: null,
      }
    }
    case `${constants.CREATE_INTERNAL_NOTES}/rejected`: {
      const error = action.payload.data?.errors ?
          action.payload.data?.errors[0]?.message :
          action.payload.data?.error
      return {
        ...state,
        createInternalNotesStatus: 'rejected',
        createInternalNotesError: error || action.payload || action.error?.message,
      }
    }
    case `${constants.GET_LATEST_INTERNAL_NOTES}/fulfilled`: {
      return {
        ...state,
        latestInternalNote: action.payload,
      }
    }
    case `${constants.RESET_INTERNAL_NOTES}`: {
      return {
        ...state,
        latestInternalNote: '',
        createInternalNotesStatus: 'idle',
        createInternalNotesError: null
      }
    }
    default: return {...state}
  }


}

export default reducer
