import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Paper, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';

const otherSetting = {
  height: 300,
  
  grid: { horizontal: true },
  sx: {
    backgroundColor: 'rgba(255, 255, 255, 0.125)',
    borderRadius: '16px',
    padding: '8px',
    [`& .${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translateX(-24px)',
    },
    [`& .${axisClasses.right} .${axisClasses.label}`]: {
      transform: 'translateX(24px)',
    },
    [`& .${axisClasses.directionX} .${axisClasses.tickContainer}:nth-of-type(even) .${axisClasses.tickLabel}`]: {
      transform: 'translateY(12px)',
    }
  },
};

const StyledChartContainer = styled(Paper)(({ theme }) => ({ 
  ['& g.MuiChartsLegend-root']: {
    display: 'none'
  },
  [theme.breakpoints.up('xs')]: {
    ['& g.MuiChartsLegend-root']: {
      display: 'block'
    },
  },
  maxWidth: '1035px',
  borderRadius: '16px', 
  padding: '24px', 
  background: 'url("https://cdn.getstabl.com/images/eligibility_header.png") 0% 0% / cover'
}))

const addCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Graph = ({sdk: {
  advancesSummary: {
    series
  }
}}) => {
  return (
    <StyledChartContainer>
      <LineChart
        rightAxis="count_of_advances"
        yAxis={[{ 
          label: 'Daily Advances Total', 
          id: 'sum_of_advances' 
        },{
          label: 'Daily Advances Count', 
          id: 'count_of_advances'
        }]}
        xAxis={[
          {
            scaleType: 'band',
            data: series.map((d) => d.date),
            tickNumber: series.length
          },
        ]}
        series={[{ 
          data: series.map(d => d.sum_of_advances), 
          yAxisKey: 'sum_of_advances',
          label: `Sum of Advances`, 
          valueFormatter: value => `$${addCommas(value)}`,
          color: 'var(--Primary-Navy, #546A7B)'
        },{ 
          data: series.map(d => d.count_of_advances), 
          yAxisKey: 'count_of_advances',
          label: `Number of Advances`, 
          color: 'var(--Primary-Navy, #C0D9DE)',
          valueFormatter: value => addCommas(value)
        }]}
        {...otherSetting}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'center',
          marginTop: '16px',
        
        }}
      >
        <Typography variant="h6" sx={{ color: 'white',  }}>Total Sum of Advances: ${addCommas(series.reduce((acc, next) => acc + next.sum_of_advances, 0))}</Typography>
        <Typography variant="h6" sx={{ color: 'white',  }}>Total Count of Advances: {addCommas(series.reduce((acc, next) => acc + next.count_of_advances, 0))}</Typography>
      </Box>
    </StyledChartContainer>
  );
}

export default Graph;