import React from 'react'
import { TextField, Button } from '@mui/material'
import styled from '@mui/material/styles/styled'
import ClearIcon from '@mui/icons-material/Clear';

const StyledSearchBar = styled('div')(({ theme }) => ({ 
  width: '66%',
  minWidth: '300px',
  marginTop: theme.spacing(2)
}))

const SearchBar = props => {
  const { onReady } = props 
  
  let timeout
  const debounce = func => {
    return function(e) {
      const later = () => {
        clearTimeout(timeout)
        func(e.target.value)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, 500)
    }
  }

  const formRef = React.useRef(null)
  const textRef = React.useRef(null)

  return (
    <StyledSearchBar>
      <form ref={formRef} onSubmit={(e) => {
        e.preventDefault()
        clearTimeout(timeout)
        const value = textRef.current.querySelector('input').value
        onReady(value)
      }}>
        <TextField 
          fullWidth
          ref={textRef}
          label='Employee Search'
          onChange={debounce(onReady)}
          InputProps={{
            endAdornment: <Button 
                            onClick={() => {
                              const value = textRef.current.querySelector('input').value
                              if (value === '') return
                              formRef.current.reset()
                              onReady()
                            }}>
                              <ClearIcon/>
                          </Button>
          }}
        />
      </form>
    </StyledSearchBar>
  )
}

export default SearchBar