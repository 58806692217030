import React from 'react';
import Header from './header';
import Table from './table';

const HistoricalAdvances = () => {
  return (
    <>
      <Header />
      <Table />
    </>
  );
}

export default HistoricalAdvances;