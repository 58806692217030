import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Snackbar, Alert } from '@mui/material';
import styled from '@mui/material/styles/styled';

const StyledIcon = styled(InfoIcon)(({ theme }) => ({ 
  color: theme.palette.info.main
}))

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  width: '100%',
  marginTop: '75px',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '24px',
  '& > div': { 
    maxWidth: '55%',
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '65px',
    paddingRight: '0px',
    '& > div': { 
      maxWidth: '55%',
    },
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '0px',
    paddingRight: '0px',
    '& > div': { 
      maxWidth: '35%',
    },
  }
}))

const InfoNotification = (props) => { 

  const [open, setOpen] = React.useState(false)

  return (
    <div>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <StyledIcon><InfoIcon fontSize="small" /></StyledIcon>
      </IconButton>
    
    <StyledSnackbar
      id="foo"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
      
    >
        <Alert
          onClose={() => setOpen(false)}
          severity={'info'}
          variant="filled"
          
          >
            {props.message}
        </Alert>
    </StyledSnackbar>
    </div>
  )
}

export default InfoNotification