import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withEmailRecipients(Component) {
    return (props) => {

        const emailRecipients = useSelector(state => state.mySettings.emailRecipients)
        const emailRecipientsStatus = useSelector(state => state.mySettings.emailRecipientsStatus)
        const createEmailRecipientStatus = useSelector(state => state.mySettings.createEmailRecipientStatus)
        const deleteEmailRecipientStatus = useSelector(state => state.mySettings.deleteEmailRecipientStatus)
        const primaryEmailRecipientStatus = useSelector(state => state.mySettings.primaryEmailRecipientStatus)
        const dispatch = useDispatch()

        const emailRecipientsSDK = {
            ...dispatchWrapper(dispatch, api(apiClient)),
            createEmailRecipientStatus,
            deleteEmailRecipientStatus,
            primaryEmailRecipientStatus,
            emailRecipients,
            emailRecipientsStatus,
        }
        const passThruProps = {...props, emailRecipientsSDK}

        return (
            <Component {...passThruProps} />
        )
    }
}

export default withEmailRecipients