import React from 'react'
import PageHeader from '../../components/page-header';
import { 
  Typography,
  Box
} from '@mui/material';
import Welcome from './welcome';
import Graph from './graph';
import RecentAdvances from './recent-advances';
import withAdvancesSummary from './sdk/withAdvancesSummary';
import withLatestAdvances from './sdk/withLatestAdvances';
import Ghosting from './ghosting';

const Dashboard = ({latestAdvancesSDK, advancesSummarySDK}) => {
  React.useEffect(() => {
    advancesSummarySDK.findAdvancesSummary()
    latestAdvancesSDK.findLatestAdvances()
  }, [])
  React.useEffect(() => {
    window.scroll(0,0)
  },[])
  
  return (
    <Box>
      <PageHeader><Typography variant='h5'>Dashboard</Typography></PageHeader>
      <Ghosting />
      <Welcome sdk={advancesSummarySDK}/>
      <Graph sdk={advancesSummarySDK}/>
      <RecentAdvances sdk={latestAdvancesSDK} />
    </Box>
  )
}

export default withAdvancesSummary(withLatestAdvances(Dashboard))