const textfield = {
	MuiTextField: {
		styleOverrides: {
			root: ({ theme }) => ({
				'& .MuiInputBase-root': {
					color: theme.palette.primary.main,

					'&.Mui-disabled': {
						'& input': {
							color: '#fff',
							background: '#ebecec',
						},
						'& fieldset': {
							borderColor: theme.palette.darkSand.main,
						}
					},

					'& fieldset, input': {
						borderRadius: '10px',
						borderWidth: 'x'
					},

					'&:hover': {
						'& fieldset': {
							borderWidth: '1px',
							borderColor: theme.palette.primary.main,
						}
					},


				},
			}),
		}
	},
}

export default textfield