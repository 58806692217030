import { createAsyncThunk } from '@reduxjs/toolkit'
import constants from '../redux/constants'

const api = client => ({
  findMyCompanySafeguards: () => {
    return async dispatch => {
      const fetchMySafeguards = createAsyncThunk(
        constants.FIND_MY_COMPANY_SAFEGUARDS,
        async () => {
          const response = await client.get(`/api/v2/my-company-safeguards`)
          return response.data
        }
      )
      dispatch(fetchMySafeguards())
    }
  },
  patchMyCompanySafeguards: (data) => {
    return async dispatch => {
      const patchMyCompanySafeguard = createAsyncThunk(
        constants.PATCH_MY_COMPANY_SAFEGUARD,
        async () => {
          const response = await client.patch(`/api/v2/my-company-safeguards`, data)
          return response.data
        }
      )
      dispatch(patchMyCompanySafeguard())
    }
  },
  resetPatchStatus: () => {
    return async dispatch => {
      dispatch({type: constants.RESET_PATCH_STATUS})
    }
  }
})

export default api