import React from "react";
import {Box, Button, ButtonGroup, Modal, TextField, Typography} from '@mui/material'
import AdminSummary from "../admin-summary";
import withAdmins from '../sdk/withAdmins'
import styled from "@mui/material/styles/styled";
import AddIcon from "@mui/icons-material/Add";
import SaveSnackbar from "../../../components/save-snackbar";

const Section = styled('section')(() => ({
    marginBottom: '32px',
}))

const StyledHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
}))

const StyledDescription = styled('div')(({ theme }) => ({
    marginTop: '20px',
    marginBottom: '40px'
}))

const ModalContent = styled(Box)(({theme}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
}))

const BACKDROP_CLICK_REASON = 'backdropClick'

const Admins = (props) => {
    const {
        adminsSDK: {
            adminUsers,
            adminUsersStatus,
            createAdmin,
            createAdminStatus,
            getAllAdmins,
            resetAllAdmins,
            deleteAdminStatus,
        }
    } = props

    const [showEmailAdd, setShowEmailAdd] = React.useState(false)
    const [showFullForm, setShowFullForm] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [createError, setCreateError] = React.useState()
    const [showSnackbar, setShowSnackbar] = React.useState({show: false, severity: 'success'})
    const emailRef = React.useRef()
    const firstNameRef = React.useRef()
    const lastNameRef = React.useRef()


    React.useEffect(() => {
        if (adminUsersStatus === 'fulfilled') setLoading(false)
    }, [adminUsersStatus])

    React.useEffect(() => {
        if (deleteAdminStatus === 'fulfilled') {
            getAllAdmins()
        }
    }, [deleteAdminStatus])


    React.useEffect(() => {
        if (createAdminStatus === 'not_found') {
            setShowEmailAdd(false)
            setShowFullForm(true)
            setCreateError(null)
        }

        if (createAdminStatus === 'conflict') {
            setCreateError('User belongs to another company and cannot be added as an admin.')
        }

        if (createAdminStatus === 'error') {
            setCreateError('An error has occurred. Please try again later.')
        }

        if (createAdminStatus === 'fulfilled') {
            getAllAdmins()
            setShowSnackbar({show: true, severity: 'success' })
            resetAllAdmins()
            handleModalClose()
        }
    }, [createAdminStatus])


    React.useEffect(() => {
        setLoading(true)
        getAllAdmins()
        return () => resetAllAdmins()
    }, [])


    const addAdminUser = () => {
        const payload ={
            email: emailRef.current.value
        }
        if (firstNameRef.current?.value) payload.first_name = firstNameRef.current.value
        if (lastNameRef.current?.value) payload.last_name = lastNameRef.current.value
        createAdmin(payload)
    }

    const handleModalClose = (_, reason) => {
        if (reason !== BACKDROP_CLICK_REASON) {
            setShowEmailAdd(false)
            setShowFullForm(false)
            setCreateError(null)
        }
    }

    const isROGhost = sessionStorage.getItem('ghosting-role') === 'read-only'

    return (<Section>
        <StyledHeader>
            <Typography
                sx={{marginBottom: '16px'}}
                variant="h6"
                color='primary'
            >
                Admin Users
            </Typography>
            {!isROGhost && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowEmailAdd(true)}
                >
                    <AddIcon />
                    Add Admin
                </Button>
            )}
        </StyledHeader>

        <StyledDescription>
            <Typography variant="p">
                Admin users have full access to this portal. Users in this list will be able to perform actions that can affect a user's ability to take an advance.
            </Typography>
        </StyledDescription>

        {adminUsers.map((admin) => (
            <AdminSummary
                key={admin.id}
                admin={admin}
                loading={loading}
            />
        ))}

        <SaveSnackbar
            open={showSnackbar.show}
            setOpen={(show) => {
                setShowSnackbar({...showSnackbar, show})
            }}
            statusMessages={{
                success: 'Admin has been added.',
                error: "An error has occurred. Please try again later."
            }}
            severity={showSnackbar.severity}
        />

        <Modal
            open={showEmailAdd || showFullForm}
            onClose={handleModalClose}
        >
            <ModalContent>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add new Admin
                </Typography>
                {showEmailAdd && (<>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Enter the email address of the admin user.
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Adding this user will give them full access to this admin portal.
                    </Typography>
                </>)}
                {showFullForm && (<>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        This is a new user. Please include the name along with the email address of the admin user.
                    </Typography>
                    <TextField
                        sx={{ margin: '7px 0'}}
                        inputRef={firstNameRef}
                        placeholder="First Name"
                        fullWidth
                    />
                    <TextField
                        sx={{ margin: '7px 0'}}
                        inputRef={lastNameRef}
                        placeholder="Last Name"
                        fullWidth
                    />
                </>)}
                <TextField
                    sx={{ margin: '7px 0'}}
                    inputRef={emailRef}
                    placeholder="Admin Email"
                    helperText={createError}
                    error={!!createError}
                    fullWidth
                />
                <ButtonGroup sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '12px'
                }}>
                    <Button
                        onClick={handleModalClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={addAdminUser}
                    >
                        Submit
                    </Button>
                </ButtonGroup>
            </ModalContent>
        </Modal>
    </Section>)
}

export default withAdmins(Admins)