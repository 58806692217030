import PropTypes from 'prop-types'

//---------------------
// Components
//---------------------
import {
    List,
    usePagination
} from '@mui/material'
import {
    ArrowBack,
    ArrowForward
} from "@mui/icons-material";
import {
    Page,
    PageNav
} from './page-items.jsx'

//---------------------
// CSS
//---------------------
import {styled} from "@mui/material/styles";


const ListStyled = styled(List)(() => ({
    listStyle: 'none',
    display: 'flex',
    margin: 0,
}))

const Ellipsis = styled('div')(() => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
}))

const defaultProps = {
    page: 0,
    count: 1,
    onChange: () =>  console.warn('Need to implement onChange for Paginator')
}

function Paginator(props = defaultProps) {
    const {
        onChange
    } = props

    const { items } = usePagination({
        ...props,
        boundaryCount: 0,
        siblingCount: 1,
        onChange: (e, page) => onChange(page)
    })

    return (
        <nav>
            <ListStyled>
                {items.map(({ page, type, selected, ...item }, index) => {
                    let children = null;

                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = (
                            <Ellipsis>
                                …
                            </Ellipsis>
                        )
                    } else if (type === 'page') {
                        children = (
                            <Page
                                type="button"
                                variant={selected ? 'contained' : 'text'}
                                color="secondary"
                                style={{
                                    fontWeight: selected ? 'bold' : undefined,
                                }}
                                {...item}
                            >
                                {page}
                            </Page>
                        );
                    } else {
                        children = (
                            <PageNav type="button" {...item}>
                                {type === 'previous' ? <ArrowBack /> : <ArrowForward />}
                            </PageNav>
                        );
                    }

                    return <li key={index}>{children}</li>;
                })}
            </ListStyled>
        </nav>
    )
}

Paginator.propTypes = {
    page: PropTypes.number,
    count: PropTypes.number,
    onChange: PropTypes.func
}

export default Paginator