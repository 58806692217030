import * as React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Skeletonized from './skeleton';
import Tooltip from '@mui/material/Tooltip';
import {Box, Button, ButtonGroup, Modal} from "@mui/material";
import withEmailRecipients from "../sdk/withEmailRecipients";


const StyledWrapper = styled('div')(({theme}) => ({
    width: '100%',
    marginBottom: theme.spacing(1),
}))

const StyledSummary = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    minHeight: '52px'
}))

const StyledTools = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
}))

const ModalContent = styled(Box)(({theme}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
}))

const BACKDROP_CLICK_REASON = 'backdropClick'

const EmailRecipient = (props) => {

    const {
        loading,
        recipient: {
            id,
            email,
            primary,
        },
        emailRecipientsSDK: {
            deleteEmailRecipient,
            deleteEmailRecipientStatus,
            setPrimaryEmailRecipient,
        },
    } = props

    const [showDelete, setShowDelete] = React.useState(false)

    React.useEffect(() => {
        if (deleteEmailRecipientStatus === 'fulfilled') {
            setShowDelete(false)
        }
        if (deleteEmailRecipientStatus === 'error') {
            setShowDelete(false)
        }
    }, [deleteEmailRecipientStatus])


    const removeEmail = (e) => {
        e.preventDefault()
        deleteEmailRecipient(id)
    }

    const handleModalClose = (_, reason) => {
        if (reason !== BACKDROP_CLICK_REASON) {
            setShowDelete(false)
        }
    }

    const handleSetPrimary = () => {
        setPrimaryEmailRecipient(id)
    }

    return (<>
        <div>
            {loading ? <Skeletonized/> : (
                <>
                    <Paper>
                        <StyledWrapper>
                            <AccordionSummary>
                                <StyledSummary>
                                    <div>
                                        <Typography>{email}</Typography>

                                        <Typography variant="p">{primary ? 'Primary' : 'Carbon Copy'}</Typography>
                                    </div>
                                    <StyledTools>
                                        {!primary && (<>
                                            <Button
                                                onClick={handleSetPrimary}
                                                variant="primary"
                                            >
                                                Set Primary
                                            </Button>
                                            <Tooltip
                                                placement="top-start"
                                                title={"Remove from recipients"}
                                            >
                                                <span>
                                                  <IconButton
                                                      onClick={() => setShowDelete(true)}
                                                  >
                                                      <CloseIcon sx={{fontSize: '36px', color: '#546A7B'}}/>
                                                  </IconButton>
                                                </span>
                                            </Tooltip>
                                        </>)}
                                    </StyledTools>
                                </StyledSummary>

                            </AccordionSummary>

                        </StyledWrapper>
                    </Paper>

                </>
            )}
        </div>



        <Modal
            open={showDelete}
            onClose={handleModalClose}
        >
            <ModalContent>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Remove {email}?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    The email will no longer receive deduction reports.
                </Typography>
                <ButtonGroup sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '12px'
                }}>
                    <Button
                        onClick={handleModalClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={removeEmail}
                    >
                        Remove
                    </Button>
                </ButtonGroup>
            </ModalContent>
        </Modal>
    </>)

}

export default withEmailRecipients(EmailRecipient)