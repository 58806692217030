import Skeleton from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled'
import {
    ButtonGroup,
    Button,
    Box,
    Typography,
    Paper,
} from '@mui/material'

const StyledRow = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    span: {
        margin: '10px'
    }
}))

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: "16px 0",
}))


const Skeletonized = () => {
    return (
        <Box>
            <Typography sx={{marginBottom: '16px'}} variant="h6">Multi Factor Authentication</Typography>
            <StyledForm>
                <Paper sx={{padding: '8px'}}>
                    <StyledRow>
                        <Skeleton variant="rectangular" width={200} height={130} />
                        <Skeleton variant="rectangular" width={200} height={130} />
                    </StyledRow>
                </Paper>
            </StyledForm>
        </Box>
    )
}

export default Skeletonized;