//---------------------
// Components
//---------------------
import {
    Button,
    IconButton
} from '@mui/material'

//---------------------
// CSS
//---------------------
import { styled } from "@mui/material/styles";

const themeAccessor = ({ theme }) => ({
    minWidth: '50px',
    height: '50px',
    width: '50px',
    margin: '0 7px',
    color: theme.palette.primary.main
})

//-------------------------
// Component: Styled button for pagination
//-------------------------
export const Page = styled(Button)(themeAccessor)

export const PageNav = styled(IconButton)(themeAccessor)