import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import Auth0 from './auth0';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import theme from './theme';
import store from './store'
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';

const root = ReactDOM.createRoot(document.getElementById('root'));
const uriGrabber = (isGhost = false) => {

  const navigationEntry = window.performance.getEntriesByType('navigation')[0];
  const navigationType = navigationEntry.type;
  const isPageReload = navigationType === 'reload';
  
  const {
    origin,
    href,
    search,
    pathname
  } = window.location
  if(isGhost && pathname.length > 1 && !isPageReload){
    window.location.replace(origin, '')
    return
  } 
  if(/code/.test(search)) return 
  const path = href.replace(origin, '')
  // if(window.sessionStorage.getItem('loaded')) return
  window.localStorage.setItem('redirectPath', path)
}

const GhostGrabber = (props) => {

  const {
    pathname
  } = window.location
  
  const [ waiting, setWaiting ] = React.useState(true)
  const [ isGhost, setIsGhost ] = React.useState(false)
  const { getAccessTokenSilently } = useAuth0()

  if(pathname === '/logout' || pathname === '/') return props.children

  getAccessTokenSilently().then(token => {
    const { permissions } = jwtDecode(token)
    if(permissions.includes('portal:ghosting:read')){
      setIsGhost(true)
      sessionStorage.setItem('ghosting-role', 'read-only')
    }
    setWaiting(false)
  })
  if(waiting) return null 
  uriGrabber(isGhost)
  return props.children
}



root.render(
  <div
    style={{
      backgroundColor: 'rgba(84,106,123, 0.125)'
    }}
  >
    <CssBaseline />
    <Auth0>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GhostGrabber>
              <App />
            </GhostGrabber>
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </Auth0>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
