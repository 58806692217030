import { createAsyncThunk } from '@reduxjs/toolkit'
import constants from '../redux/constants'
import { create } from '@mui/material/styles/createTransitions'

const api = client => ({
  create: (data) => {
    return async dispatch => {
      const createEmployee = createAsyncThunk(
        constants.CREATE_EMPLOYEE,
        async () => {
          const response = await client.post('/api/v2/my-ewa-employees', data)
          .catch(e => {
              const { errors } = e.response.data
              const [first] = errors
              e.message = first.message
              throw e
            })
        return response.data
        })      
      dispatch(createEmployee())
    }
  },
  findAll: (offset, limit, search = '', activeOnly = true) => {
    return async dispatch => {
      const query = new URLSearchParams()
      if(activeOnly) query.append('active_only', 'true')
      query.append('offset', offset)
      query.append('limit', limit)
      if(search.length) query.append('search', search)
      const fetchEmployees = createAsyncThunk(
        constants.FIND_ALL_EMPLOYEES,
        async (query) => {
          const response = await client.get(`/api/v2/my-ewa-employees?${query}`)
          return response.data
        }
      )
      dispatch(fetchEmployees(query))
    }
  },
  findEmployeesPage: (offset, limit, search = '', activeOnly = true) => {
    return async dispatch => {
      const query = new URLSearchParams()
      if(activeOnly) query.append('active_only', 'true')
      query.append('offset', offset)
      query.append('limit', limit)
      if(search.length) query.append('search', search)
      const fetchEmployees = createAsyncThunk(
          constants.FIND_EMPLOYEES_PAGE,
          async (query) => {
            const response = await client.get(`/api/v2/my-ewa-employees?${query}`)
            return response.data
          }
      )
      dispatch(fetchEmployees(query))
    }
  },
  findOne: (id) => {
    return async dispatch => {
      const fetchEmployee = createAsyncThunk(
        constants.FIND_ONE_EMPLOYEE,
        async () => {
          const response = await client.get(`/api/v2/my-ewa-employees/${id}`)
          return response.data
        }
      )
      dispatch(fetchEmployee())
    }
  },
  findEmployeeAdvances: (employeeID, previousPayperiod) => {
    let url = `/api/v2/my-ewa-employees/${employeeID}/advances`
    if(previousPayperiod) url += `?previous_pay_period=${previousPayperiod}`
    return async dispatch => {
      const fetchEmployeeAdvances = createAsyncThunk(
        constants.FIND_EMPLOYEE_ADVANCES,
        async () => {
          const response = await client.get(url)
          return response.data
        }
      )
      dispatch(fetchEmployeeAdvances())
    }
  },
  getAuth0MFAMethods: (userId) => {
    return async dispatch => {
      const resetMFA = createAsyncThunk(
          constants.FIND_EMPLOYEE_MFA_METHODS,
          async () => {
            const response = await client.get(`/api/v2/my-ewa-employees/${userId}/mfa-methods`)
            return response.data
          }
      )
      dispatch(resetMFA())
    }
  },
  resetAuth0MFA: (id) => {
    return async dispatch => {
      const resetMFA = createAsyncThunk(
        constants.RESET_EMPLOYEE_MFA_METHODS,
        async () => {
          const response = await client.delete(`/api/v2/my-ewa-employees/${id}/reset-phone-mfa`)
          return response.data
        }
      )
      dispatch(resetMFA())
    }
  },
  removeAuth0MFAMethod: (userId, methodId) => {
    return async dispatch => {
      const resetMethodMFA = createAsyncThunk(
          constants.REMOVE_AUTH0_MFA_METHOD,
          async () => {
            const response = await client.delete(`/api/v2/my-ewa-employees/${userId}/reset-mfa/${methodId}`)
            return response.data
          }
      )
      dispatch(resetMethodMFA())
    }
  },
  resetAuth0MFAMethods: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_EMPLOYEE_MFA_METHODS })
    }
  },
  findEmployeeSafeguards: id => {
    return async dispatch => {
      const fetchEmployeeSafeguards = createAsyncThunk(
        constants.FIND_EMPLOYEE_SAFEGUARDS,
        async () => {
          const response = await client.get(`/api/v2/my-ewa-employees/${id}/employee-safeguards`)
          return response.data
        }
      )
      dispatch(fetchEmployeeSafeguards())
    }
  },
  updateEmployee: (id, data) => {
    return async dispatch => {
      const updateEmployee = createAsyncThunk(
        constants.UPDATE_EMPLOYEE,
        async () => {
          const response = await client.put(`/api/v2/my-ewa-employees/${id}`, data)
            .catch(e => {
              const { errors } = e.response.data
              const [first] = errors
              e.message = first.message
              throw e
            })
          return response.data
        }
      )
      dispatch(updateEmployee())
    }
  },
  updateEmployeeSafeguards: (id, data) => {
    return async dispatch => {
      const payload = {
        absolute_max_eligible_amount_per_pay_period: parseFloat(data.maxAdvance),
        earnings_percentage_limit: parseFloat(data.maxPercentage),
        max_hours_per_day: parseFloat(data.maxHours)
      }

      const updateEmployeeSafeguards = createAsyncThunk(
        constants.UPDATE_EMPLOYEE_SAFEGUARDS,
        async () => {
          const response = await client.put(`/api/v2/my-ewa-employees/${id}/employee-safeguards`, payload)
          return response.data
        }
      )
      dispatch(updateEmployeeSafeguards())
    }
  },
  toggleEmployeeStatus: (id, status) => {
    return async dispatch => {
      const toggleStatus = createAsyncThunk(
        constants.PATCH_EMPLOYEE_STATUS,
        async () => {
          const response = await client.patch(`/api/v2/my-ewa-employees/${id}/active`, { is_active: status })
          return response.data
        }
      )
      dispatch(toggleStatus())
    }
  },
  togglePendingTermination: (id, status) => {
    return async dispatch => {
      const toggleStatus = createAsyncThunk(
        constants.PATCH_EMPLOYEE_PENDING_TERMINATION,
        async () => {
          const response = await client.patch(`/api/v2/my-ewa-employees/${id}/pending-termination`, { pending_termination: status })
          return response.data
        }
      )
      dispatch(toggleStatus())
    }
  },
  resetAllEmployees: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_ALL_EMPLOYEES })

    }
  },
  resetEmployee: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_EMPLOYEE })
    }
  },
  resetEmployeeAdvances: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_EMPLOYEE_ADVANCES })
    }
  },
  resetCreatedEmployee: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_CREATED_EMPLOYEE })
    }
  },
  resetSafeguards: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_EMPLOYEE_SAFEGUARDS })
    }
  },
  findMyCompanyRuleset: () => {
    return async dispatch => {
      const fetchCompanyRuleset = createAsyncThunk(
        constants.FIND_MY_COMPANY_RULESET,
        async () => {
          const response = await client.get('/api/v2/my-company-ruleset')
          return response.data
        }
      )
      dispatch(fetchCompanyRuleset())
    }
  },
  getHourlyEOB: (employeeID) => {
    return async dispatch => {
      const fetchHourlyEOB = createAsyncThunk(
        constants.GHOSTING_FIND_EMPLOYEE_EOB_HOURLY,
        async () => {
          const response = await client.get(`/api/v2/my-ghosting/ewa-employee/${employeeID}/eob/hourly`)
          return response.data
        }
      )
      dispatch(fetchHourlyEOB())
    }
  },
  getSalaryEOB: (employeeID) => {
    return async dispatch => {
      const fetchSalaryEOB = createAsyncThunk(
        constants.GHOSTING_FIND_EMPLOYEE_EOB_SALARY,
        async () => {
          const response = await client.get(`/api/v2/my-ghosting/ewa-employee/${employeeID}/eob/salary`)
          return response.data
        }
      )
      dispatch(fetchSalaryEOB())
    }
  },
  resetEOB: () => {
    return async dispatch => {
      dispatch({ type: constants.GHOSTING_RESET_EOB })
    }
  },
  createTier3Escalation: (payload) => {
    return async dispatch => {
      const escalateToTier3 = createAsyncThunk(
        constants.GHOSTING_ESCALATE_TO_TIER_3,
        async () => {
          const response = await client.post('/api/v2/my-ghosting/tier-3-support-escalation', payload)
          return response.data
        }
      )
      dispatch(escalateToTier3())
    }
  },
  resetEscalationStatus: () => {
    return async dispatch => {
      dispatch({ type: constants.GHOSTING_ESCALATE_TO_TIER_3_RESET })
    }
  },
  createEwaEmployeeOtp: (userId) => {
    return async dispatch => {
      const createOtp = createAsyncThunk(
          constants.CREATE_EWA_EMPLOYEE_OTP,
          async () => {
            const response = await client.post(`/api/v2/my-ghosting/ewa-employee/${userId}/create-otp`)
            return response.data
          }
      )
      dispatch(createOtp())
    }
  },
  removeAuth0MFAMethodWithOtp: (userId, methodId, otp) => {
    return async dispatch => {
      const resetMethodMFA = createAsyncThunk(
          constants.REMOVE_AUTH0_MFA_METHOD,
          async (_, thunkAPI) => {
            try {
              const response = await client.delete(`/api/v2/my-ghosting/ewa-employee/${userId}/reset-mfa/${methodId}?otp_code=${otp}`)
              return response.data
            } catch(e) {
              return thunkAPI.rejectWithValue({
                data: { error: 'Employee not found.' },
                status: e.response?.status
              })
            }
          }
      )
      dispatch(resetMethodMFA())
    }
  },
  sendEwaEmployeeWelcomeEmail: (userId) => {
    return async dispatch => {
      const sendWelcomeEmail = createAsyncThunk(
          constants.SEND_EWA_EMPLOYEE_WELCOME_EMAIL,
          async (_, thunkAPI) => {
            try {
              const response = await client.put(`/api/v2/my-ewa-employees/${userId}/welcome-email`)
              return response.data
            } catch(e) {
              return thunkAPI.rejectWithValue({
                data: e.response?.data,
                status: e.response?.status
              })
            }
          }
      )
      dispatch(sendWelcomeEmail())
    }
  },

  createInternalNotes: (userId, note) => {
    return async dispatch => {
      const createNotes = createAsyncThunk(
          constants.CREATE_INTERNAL_NOTES,
          async (_, thunkAPI) => {
            try {
              const response = await client.post(`/api/v2/my-ewa-employees/${userId}/notes`, { note })
              return response.data
            } catch(e) {
              return thunkAPI.rejectWithValue({
                data: e.response?.data,
                status: e.response?.status
              })
            }
          }
      )
      dispatch(createNotes())
    }
  },
  getLatestInternalNotes: (userId) => {
    return async dispatch => {
      const getLatestNotes = createAsyncThunk(
          constants.GET_LATEST_INTERNAL_NOTES,
          async (_, thunkAPI) => {
            try {
              const response = await client.get(`/api/v2/my-ewa-employees/${userId}/notes/latest`)
              return response.data
            } catch(e) {
              return thunkAPI.rejectWithValue({
                data: e.response?.data,
                status: e.response?.status
              })
            }
          }
      )
      dispatch(getLatestNotes())
    }
  },
  resetInternalNotes: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_INTERNAL_NOTES })
    }
  }
})

export default api
