import React from 'react'
import DollarCircle from '../../components/icons/dollar-circle'
import { 
  Typography, 
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  styled,
} from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',
}));

const amountFormatter = (amount) => `$${(amount.cents/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
const timeAgoFormatter = (date) => moment(date).fromNow()

const RecentAdvances = ({sdk: {
  latestAdvances
}}) => {  
  const navigate = useNavigate();
  const onAdvanceClick = id => () => { 
    navigate(`/my-employees/view/${id}`)
  }
  return (
    <Box
      sx={{
        maxWidth: 'calc(1035px - 18px)',
        marginLeft: '18px',
      }}
    >
      <Box
        sx={{
          marginLeft: '-16px',
          marginTop: '40px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '16px',
          flexDirection: 'row', 
          width: '100%'
        }}
      >
        <DollarCircle sx={{fontSize: '2.1875rem'}} />
        <Typography variant="h5">Recent Advances</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // gap: '24px',
          width: '100%',
          // 
        }}
      >
        <Grid container spacing={2}>
          <Demo>
            <List
              sx={{
                width: '100%',
                backgroundColor: 'white',
                // padding: '16px',
              }}
          >
              {latestAdvances.map(({
                ewa_employee_id,
                first_name,
                last_name,
                amount,
                created_at
              }, index) => (
                <ListItem
                  key={ewa_employee_id+index}
                  sx={{
                    width: '100%',
                    borderRadius: '10px',
                    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
                    backgroundColor: 'white',
                    // padding: '16px',
                    margin: '8px 0',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                  secondaryAction={
                    <IconButton edge="end" aria-label="open"  onClick={onAdvanceClick(ewa_employee_id)}>
                      <OpenInNewOutlinedIcon/>
                    </IconButton>
                  }
                >
               <ListItemText
                    primary={<span>{first_name} {last_name}</span>}
                    secondary={<span>Advanced {amountFormatter(amount)} about {timeAgoFormatter(created_at)}</span>}
                  />
                </ListItem>
              ))}
            </List>
          </Demo>
        </Grid>
      </Box>
    </Box>
  );
}

export default RecentAdvances;  