import ghosting from "../ghosting";
import constants from "./constants";
import moment from "moment";

const initState = {
  advancesSummaryStatus: "idle",
  latestAdvancesStatus: "idle",
  ghostingStatus: "idle",
  advancesSummary: {
    pay_period_start: "",
    pay_period_end: "",
    deduction_report_date: "",
    series: [],
  },
  ghostingCompanies: [],
  latestAdvances: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case `${constants.FIND_MY_COMPANY_ADVANCES_SUMMARY}/pending`: {
      return {
        ...state,
        advancesSummaryStatus: "pending",
      };
    }
    case `${constants.FIND_MY_COMPANY_ADVANCES_SUMMARY}/fulfilled`: {
      const {
        pay_period_end,
        pay_period_start,
        series,
        deduction_report_date,
      } = action.payload;

      return {
        ...state,
        advancesSummaryStatus: "fulfilled",
        advancesSummary: {
          pay_period_end,
          pay_period_start,
          deduction_report_date,
          series,
        },
      };
    }
    case `${constants.FIND_MY_COMPANY_ADVANCES_SUMMARY}/rejected`: {
      return {
        ...state,
        advancesSummaryStatus: "rejected",
      };
    }
    case `${constants.FIND_MY_EMPLOYEES_LATEST_ADVANCES}/pending`: {
      return {
        ...state,
        latestAdvancesStatus: "pending",
      };
    }
    case `${constants.FIND_MY_EMPLOYEES_LATEST_ADVANCES}/fulfilled`: {
      return {
        ...state,
        latestAdvancesStatus: "fulfilled",
        latestAdvances: action.payload,
        //todo remove this mocking data
        // latestAdvances: action.payload.map((advance, index) => ({
        //   ...advance,
        //   created_at: moment().subtract('minutes', index + 1).format('YYYY-MM-DDTHH:mm:ss.sssZ')
        // }))
      };
    }
    case `${constants.FIND_MY_EMPLOYEES_LATEST_ADVANCES}/rejected`: {
      return {
        ...state,
        latestAdvancesStatus: "rejected",
      };
    }
    case `${constants.FIND_MY_GHOSTING_COMPANIES}/pending`: {
      return {
        ...state,
        ghostingStatus: "pending",
      };
    }
    case `${constants.FIND_MY_GHOSTING_COMPANIES}/fulfilled`: {
      return {
        ...state,
        ghostingStatus: "idle",
        ghostingCompanies: action.payload,
      };
    }
    case `${constants.FIND_MY_GHOSTING_COMPANIES}/rejected`: {
      return {
        ...state,
        ghostingStatus: "rejected",
      };
    }
    case `${constants.UPDATE_MY_GHOSTING_COMPANY}/pending`: {
      return {
        ...state,
        ghostingStatus: "pending",
      };
    }
    case `${constants.UPDATE_MY_GHOSTING_COMPANY}/fulfilled`: {
      let activeIndex = state.ghostingCompanies.findIndex(c => c.id === action.payload.id)
      const reset = state.ghostingCompanies.map(c => ({...c, active: false}))
      const next = [...reset.slice(0, activeIndex), action.payload, ...reset.slice(activeIndex + 1)]
      return {
        ...state,
        ghostingStatus: "idle",
        ghostingCompanies: next,
      };
    }
    case `${constants.UPDATE_MY_GHOSTING_COMPANY}/rejected`: {
      return {
        ...state,
        ghostingStatus: "rejected",
      };
    }
    default:
      return { ...state };
  }
};

export default reducer;
