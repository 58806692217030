import { default as MuiButton } from './button.js'
import { default as MuiMenuItem } from './menu-item.js'
import { default as MuiSelect } from './select.js'
import { default as MuiTextField } from './textfield.js'
import { default as MuiTabs } from './tabs.js'

const overrides = {
	...MuiButton,
	...MuiMenuItem,
	...MuiSelect,
	...MuiTextField,
	...MuiTabs
}
export default overrides