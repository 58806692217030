import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withAdmins(Component) {
    return (props) => {

        const adminUsers = useSelector(state => state.mySettings.adminUsers)
        const adminUsersStatus = useSelector(state => state.mySettings.adminUsersStatus)
        const createAdminStatus = useSelector(state => state.mySettings.createAdminStatus)
        const deleteAdminStatus = useSelector(state => state.mySettings.deleteAdminStatus)
        const dispatch = useDispatch()

        const adminsSDK = {
            ...dispatchWrapper(dispatch, api(apiClient)),
            adminUsers,
            adminUsersStatus,
            createAdminStatus,
            deleteAdminStatus
        }
        const passThruProps = {...props, adminsSDK}

        return (
            <Component {...passThruProps} />
        )
    }
}

export default withAdmins