import React from 'react'
import {
  Typography,
} from '@mui/material'
import styled from '@mui/material/styles/styled'
import withEmployee from '../../sdk/withEmployee';
import { useLocation } from 'react-router-dom'

const StyledEmployee = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
}))


const LocalPageHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '6px 24px 26px 24px',
  position: 'sticky',
  top: '70px',
  marginLeft: '-16px',
  zIndex: 2,
  width: 'calc(100% + 32px)',
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
  '& > *': {
    flexGrow: 1
  },
  [theme.breakpoints.up('md')]: {
    top: 0
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '-24px',
    width: 'calc(100% + 48px)',
    top: 0
  }
}))


const EmployeeHeader = (props) => {
  const location = useLocation()
  const {
    employeeSDK: {
      employee,
      findOne,
    }
  } = props

  React.useEffect(() => {
    const [,,,id] = location.pathname.split('/')
    findOne(id)
  }, [])


  return (
    <LocalPageHeader>
        <StyledEmployee>
          <Typography variant="h5">Historical Advances</Typography>
          <Typography variant="h6">{employee.firstName || ''} {employee.lastName || ''}</Typography>
        </StyledEmployee>
      </LocalPageHeader> 
  )
}

export default withEmployee(EmployeeHeader)