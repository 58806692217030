import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import Router from "./routes";
import SideNavigation from "./navigation";
import { usingAccessToken } from "./utils/sdk";
import CopyPasteUrl from "./components/copy-paste-url";
import styled from "@mui/material/styles/styled";
import Loading from "./components/loading";

const StyledApp = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: theme.breakpoints.values.lg,
  borderRight: "1px solid rgba(0,0,0,0.12)",
  marginTop: "72px",
  [theme.breakpoints.up("md")]: {
    marginTop: "0px",
  },
}));

const Main = styled(Box)(({ theme }) => ({ 
  minWidth: "360px",
  width: '100%',
  flexGrow: 1,
  backgroundColor: "var(--Primary-White, #FFFFFF)",
  padding: "16px",
  [theme.breakpoints.up("lg")]: {
    padding: "24px",
    width: "calc(100% - 285px)",
  },
}))

function App() {
  const {
    isLoading,
    getAccessTokenSilently,
    isAuthenticated,
    logout,
    loginWithRedirect,
  } = useAuth0();
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    window.scroll(0,0)
  },[])

  React.useEffect(() => {
    if (!isLoading) {
      usingAccessToken(getAccessTokenSilently, logout);
      setReady(true);
    }
  }, [isLoading, getAccessTokenSilently, logout]);

  if (isLoading || !ready) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({ logoutParams: { returnTo: window.location.origin } });
    return <></>;
  }

  return (
    <StyledApp
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "1600px",
        borderRight: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <SideNavigation />
      <Main>
        <Router />
      </Main>
      <CopyPasteUrl />
    </StyledApp>
  );
}

export default App;
