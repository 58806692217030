import React from 'react'
import { 
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,

} from '@mui/material'


const InfoCard = (props) => {
  const { 
    headerText,
    bodyText,
    valueText,
  } = props 

  return (
    <Card sx={{ minWidth: 350, maxWidth: 950,  }}>
    <CardContent>
      <Typography variant='h5' color="text.secondary" gutterBottom>
        {headerText}
      </Typography>
      <Typography variant="body2">
        {bodyText}
      </Typography>
      <Typography sx={{marginTop: '15px'}} variant="h3" color="primary" align="center">
        {valueText}
      </Typography>
    </CardContent>
  </Card>
  )
}

export default InfoCard