import constants from "./constants";
import emailRecipient from "../email-recipient";

const initState = {
    adminUsers: [],
    adminUsersStatus: 'idle',
    createAdminStatus: 'idle',
    deleteAdminStatus: 'idle',

    createEmailRecipientStatus: 'idle',
    deleteEmailRecipientStatus: 'idle',
    emailRecipients: [],
    emailRecipientsStatus: 'idle',
    primaryRecipientsStatus: 'idle',
}


const reducer = (state = initState, action) => {
    switch(action.type) {
        case `${constants.GET_ALL_ADMINS}/pending`: {
            return {
                ...state,
                adminUsersStatus: 'pending'
            }
        }
        case `${constants.GET_ALL_ADMINS}/fulfilled`: {
            return {
                ...state,
                adminUsers: [...action.payload],
                adminUsersStatus: 'fulfilled'
            }
        }

        case `${constants.RESET_ALL_ADMINS}`: {
            return {
                ...state,
                adminUsersStatus: 'idle',
                createAdminStatus: 'idle',
                deleteAdminStatus: 'idle',
            }
        }


        case `${constants.CREATE_ADMIN}/pending`: {
            return {
                ...state,
                createAdminStatus: 'pending'
            }
        }
        case `${constants.CREATE_ADMIN}/fulfilled`: {
            return {
                ...state,
                createAdminStatus: 'fulfilled'
            }
        }
        case `${constants.CREATE_ADMIN}/rejected`: {
            const { status } = action.payload
            let error = 'error'
            if (status === 404) error = 'not_found'
            if (status === 409) error = 'conflict'
            return {
                ...state,
                createAdminStatus: error
            }
        }

        case `${constants.DELETE_ADMIN}/pending`: {
            return {
                ...state,
                deleteAdminStatus: 'pending'
            }
        }
        case `${constants.DELETE_ADMIN}/fulfilled`: {
            return {
                ...state,
                deleteAdminStatus: 'fulfilled'
            }
        }
        case `${constants.DELETE_ADMIN}/rejected`: {
            return {
                ...state,
                deleteAdminStatus: 'error'
            }
        }

        case `${constants.RESET_DELETE_ADMIN}`: {
            return {
                ...state,
                deleteAdminStatus: 'idle',
            }
        }

        case `${constants.CREATE_EMAIL_RECIPIENT}/pending`: {
            return {
                ...state,
                createEmailRecipientStatus: 'pending'
            }
        }
        case `${constants.CREATE_EMAIL_RECIPIENT}/fulfilled`: {
            return {
                ...state,
                createEmailRecipientStatus: 'fulfilled'
            }
        }
        case `${constants.CREATE_EMAIL_RECIPIENT}/rejected`: {
            return {
                ...state,
                createEmailRecipientStatus: 'error'
            }
        }

        case `${constants.RESET_CREATE_EMAIL_RECIPIENT}`: {
            return {
                ...state,
                createEmailRecipientStatus: 'idle',
            }
        }

        case `${constants.GET_EMAIL_RECIPIENTS}/pending`: {
            return {
                ...state,
                emailRecipientsStatus: 'pending'
            }
        }
        case `${constants.GET_EMAIL_RECIPIENTS}/fulfilled`: {
            return {
                ...state,
                emailRecipients: [...action.payload],
                emailRecipientsStatus: 'fulfilled'
            }
        }

        case `${constants.DELETE_EMAIL_RECIPIENT}/pending`: {
            return {
                ...state,
                deleteEmailRecipientStatus: 'pending'
            }
        }
        case `${constants.DELETE_EMAIL_RECIPIENT}/fulfilled`: {
            const deletedRecipientIndex = state.emailRecipients.findIndex((er) => er.id === action.payload.id)
            return {
                ...state,
                emailRecipients: [
                    ...state.emailRecipients.slice(0, deletedRecipientIndex),
                    ...state.emailRecipients.slice(deletedRecipientIndex+1)
                ],
                deleteEmailRecipientStatus: 'fulfilled'
            }
        }
        case `${constants.DELETE_EMAIL_RECIPIENT}/rejected`: {
            return {
                ...state,
                deleteEmailRecipientStatus: 'error'
            }
        }

        case `${constants.RESET_DELETE_EMAIL_RECIPIENT}`: {
            return {
                ...state,
                deleteEmailRecipientStatus: 'idle',
            }
        }

        case `${constants.SET_PRIMARY_EMAIL_RECIPIENT}/pending`: {
            return {
                ...state,
                primaryEmailRecipientStatus: 'pending'
            }
        }
        case `${constants.SET_PRIMARY_EMAIL_RECIPIENT}/fulfilled`: {
            const currentPrimaryIndex = state.emailRecipients.findIndex((er) => er.primary)
            const newPrimaryIndex = state.emailRecipients.findIndex((er) => er.id === action.payload.id)
            // Update the current primary
            let emailRecipients = [
                ...state.emailRecipients.slice(0,currentPrimaryIndex),
                {
                    ...state.emailRecipients[currentPrimaryIndex],
                    primary: false
                },
                ...state.emailRecipients.slice(currentPrimaryIndex+1)
            ]
            // Update the new primary
            emailRecipients = [
                ...emailRecipients.slice(0,newPrimaryIndex),
                {
                    ...emailRecipients[newPrimaryIndex],
                    primary: true
                },
                ...emailRecipients.slice(newPrimaryIndex+1)
            ]
            return {
                ...state,
                emailRecipients,
                primaryEmailRecipientStatus: 'fulfilled'
            }
        }
        case `${constants.SET_PRIMARY_EMAIL_RECIPIENT}/rejected`: {
            return {
                ...state,
                primaryEmailRecipientStatus: 'error'
            }
        }

        case `${constants.RESET_SET_PRIMARY_EMAIL_RECIPIENT}`: {
            return {
                ...state,
                primaryEmailRecipientStatus: 'idle',
            }
        }
        default: return {...state}
    }
}

export default reducer