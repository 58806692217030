import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withLatestAdvances(Component) {
	return (props) => {

		const latestAdvances = useSelector(state => state.myDashboard.latestAdvances)
		const loadingStatus = useSelector(state => state.myDashboard.latestAdvancesStatus)
		const dispatch = useDispatch()
		const latestAdvancesSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			latestAdvances,
      loadingStatus
		}
		const passThruProps = {...props, latestAdvancesSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withLatestAdvances