import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withStablAdmin(Component) {
    return (props) => {

        const data = useSelector(state => state.stablAdmin)
        const stablAdminSDK = {
            api: api(apiClient),
            ...data
        }
        const passThruProps = {...props, stablAdminSDK}

        return (
            <Component {...passThruProps} />
        )
    }
}

export default withStablAdmin