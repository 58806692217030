import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import OpenIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import { useNavigate, } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Tooltip from '@mui/material/Tooltip';
import withWelcomeEmail from "../sdk/withWelcomeEmail";
import SaveSnackbar from "../../../components/save-snackbar";
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}))

const StyledSummary = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
}))

const StyledTools = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const ItemRow = styled('div')(({ theme }) => ({ 
  justifyContent: 'flex-start',
  display: 'flex',
  width: '100%',
  padding: '16px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '16px 0',
  }
}))

const StyledField = styled(TextField)(({ theme }) => ({
  margin: '0 24px 24px 0',
  [theme.breakpoints.up('md')]: {
    margin: '0 24px 0 0',
  },
  '& > div': {
    '::before': {
        borderBottom: 'none',
        left: 0,
        bottom: 0,
        position: 'absolute',
        right: 0,
        content: '""',
        // transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        pointerEvents: 'none',
    }, 
  },
}))

const EmployeeAccordion = (props) => {

  const [ showSnackbar, setShowSnackbar ] = React.useState({show: false, severity: 'success'})

  const { 
    employee: {
      id,
      firstName,
      lastName,
      email,
      status,
      externalEmployeeId = '',
      updated_at,
    },
    welcomeEmailSDK: {
      sendWelcomeEmailStatus,
      sendEwaEmployeeWelcomeEmail
    }
  } = props

  
  let isActive = status === 'active' ? true : false
  const navigate = useNavigate()

  const resendWelcomeEmail = (e) => {
    e.stopPropagation()
    sendEwaEmployeeWelcomeEmail(id)
  }

  const onFinancialCoaching = e => {
    e.stopPropagation()
    navigate(`/my-employees/view/${id}/financial-coaching`)
  }


  React.useEffect(() => {
    if (sendWelcomeEmailStatus === 'fulfilled') {
      setShowSnackbar({show: true, severity: 'success'})
    }
    if (sendWelcomeEmailStatus === 'rejected') {
      setShowSnackbar({show: true, severity: 'error'})
    }
  }, [sendWelcomeEmailStatus])


  const hasNeverAdopted = true
  const isROGhost = sessionStorage.getItem('ghosting-role') === 'read-only'

  return (
    <div>
        <StyledAccordion>
        <AccordionSummary>
          <StyledSummary>
            <Typography>{firstName} {lastName}</Typography>
            <StyledTools>
              {isROGhost && (
              <Tooltip placement='top-start' title={'Financial Coaching'}>
                <span>
                  <IconButton
                    onClick={onFinancialCoaching}
                  >
                    <RequestQuoteOutlinedIcon />
                  </IconButton>
                </span>
              </Tooltip>
              )}
              <Tooltip placement="top-start" title={hasNeverAdopted ? "Resend Welcome Email": "Employee uses StablPay"}>
                <span>
                  <IconButton
                    onClick={resendWelcomeEmail}
                  >
                    <ForwardToInboxIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip placement="top-start" title={isActive ? "Employee is Active": "Employee is Termed"}>
                <span>
                  <IconButton disabled>{isActive ? <ToggleOnIcon sx={{fontSize: '36px', color: '#546A7B'}}/> : <ToggleOffIcon  sx={{fontSize: '36px'}}/>}</IconButton>
                </span>
              </Tooltip>
              <Tooltip  placement="top-start" title="View Employee">
                <IconButton
                  onClick={() => navigate(`/my-employees/view/${id}`)}
                >
                  <OpenIcon />
                </IconButton>
              </Tooltip>
            </StyledTools>
          </StyledSummary>
          
        </AccordionSummary>
        <AccordionDetails>
          <ItemRow>
            <StyledField 
              label="Email" 
              value={email} 
              variant={'standard'}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
            <StyledField
              label="External ID"
              value={externalEmployeeId || ''}
              variant='standard'
              InputLabelProps={{
                readOnly: true,
              }}
              fullWidth
            />
            <StyledField 
              label="Last Updated"
              value={moment(updated_at).format('MM/DD/YYYY')}
              variant='standard'
              InputLabelProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </ItemRow>
        </AccordionDetails>
        
      </StyledAccordion>

      <SaveSnackbar
          open={showSnackbar.show}
          setOpen={(show) => {
            setShowSnackbar({...showSnackbar, show})
          }}
          statusMessages={{
            success: 'Employee welcome email has been sent.',
            error: "An error has occurred. Please try again later."
          }}
          severity={showSnackbar.severity}
      />
    </div>
  )

}

export default withWelcomeEmail(EmployeeAccordion)