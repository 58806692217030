import {
  Snackbar,
  Alert
} from '@mui/material'


const SaveSnackbar = props => {
  const { open, setOpen, statusMessages, severity } = props
  return (
    <Snackbar 
        open={open} 
        autoHideDuration={6000} 
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
      >
      <Alert
        onClose={() => setOpen(false)}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        { [statusMessages[severity]] }
      </Alert>
    </Snackbar>
  )
}

export default SaveSnackbar