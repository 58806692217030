import React from "react";
import { Box, TextField, Paper, Button, ButtonGroup, Typography, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import withSupportEscalation from "../../sdk/withSupportEscalation";
import SaveSnackbar from "../../../../components/save-snackbar";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: "16px",
}));

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  marginTop: "16px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  width: '100%'
}))

const StyledHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: '100%',
  marginBottom: "16px",
 }))

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up("sm")]: {
    width: '50%'
  }
}))

const StyledFields = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: '100%',
  gap: '16px'
}))

const EscalationForm = (props) => {
  const [ open, setOpen ] = React.useState(false);

  const { onCancel, setEscalate, escalationSupportSDK } = props;
  const {
    escalationStatus,
    createTier3Escalation,
    resetEscalationStatus
  } = escalationSupportSDK;

  React.useEffect(() => {
    if(escalationStatus === 'fulfilled' || escalationStatus === 'rejected'){
      setOpen(true);
    }
    
  }, [escalationStatus])

  const { 
    user: {
      user: {
        firstName,
        lastName,
      },
      name
    }
  } = useAuth0();

  const { pathname } = useLocation();
  const [ id ] = pathname.split("/").reverse()

  const hubspotTicketRef = React.useRef(null);
  const reasonRef = React.useRef(null);

  const escalateSupport = (e) => {
    e.preventDefault();
    const payload = {
      hubspot_ticket_number: hubspotTicketRef.current.querySelector('input').value,
      notes: reasonRef.current.querySelector('textarea').value,
      ewa_employee_id: id,
    }
    createTier3Escalation(payload);
  };


  const onSnackbarClose = () => {
    if(escalationStatus === 'fulfilled'){
      setOpen(false);
      setEscalate(false);
    } else if(escalationStatus === 'rejected'){
      setOpen(false)
    }
    resetEscalationStatus();
  }

  let severity = escalationStatus === 'fulfilled' ? 'success' : escalationStatus === 'rejected' ? 'error' : ''
  return (
    <>
    <StyledPaper 
      component="form" 
      onSubmit={escalateSupport} 
      autoComplete="off"
    >
      <StyledBox>
        <StyledHeader>
          <Typography variant="h6">Escalation Form</Typography>
        </StyledHeader>
        <StyledFields>
        <TextField 
          fullWidth
          label="Reporter"
          value={`${firstName} ${lastName} (${name})`}
          readOnly
        />
        <TextField
          fullWidth
          label="Employee ID"
          value={`${id}`}
          readOnly
        />
        <TextField 
          fullWidth
          label="Hubspot Ticket Number" 
          required
          ref={hubspotTicketRef}
        />
        <TextField 
          fullWidth
          label="Reason for Escalation" 
          multiline
          rows={10}
          required
          ref={reasonRef}
        />        
        </StyledFields>
        <StyledButtonGroup>
          <Button
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button 
            type="submit"
            variant="contained"
          >Submit</Button>
        </StyledButtonGroup>
      </StyledBox>
    </StyledPaper>
    <SaveSnackbar
        open={open}
        severity={severity}
        statusMessages={{
          success: 'Escalation submitted successfully',
          error: 'Failed to submit escalation'
        }}
        setOpen={onSnackbarClose}
    />
    </>
  )
};

export default withSupportEscalation(EscalationForm);
