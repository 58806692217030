import { createAsyncThunk } from '@reduxjs/toolkit'
import constants from '../redux/constants'

const api = client => ({
  findMyCompanySafeguards: () => {
    return async dispatch => {
      const fetchMySafeguards = createAsyncThunk(
        constants.FIND_MY_COMPANY_SAFEGUARDS,
        async () => {
          const response = await client.get(`/api/v2/my-company-safeguards`)
          return response.data
        }
      )
      dispatch(fetchMySafeguards())
    }
  },
  findAdvancesSummary: () => {
    return async dispatch => {
      const fetchAdvancesSummary = createAsyncThunk(
        constants.FIND_MY_COMPANY_ADVANCES_SUMMARY,
        async () => {
          const response = await client.get(`/api/v2/my-company-advances-summary`)
          return response.data
        }
      )
      dispatch(fetchAdvancesSummary())
    }
  },
  findLatestAdvances: () => {
    return async dispatch => {
      const fetchLatestAdvances = createAsyncThunk(
        constants.FIND_MY_EMPLOYEES_LATEST_ADVANCES,
        async () => {
          const response = await client.get(`/api/v2/my-ewa-employees/advances/latest`)
          return response.data
        }
      )
      dispatch(fetchLatestAdvances())
    }
  },
  findMyGhostingCompanies: () => {
    return async dispatch => {
      const fetchMyGhostingCompanies = createAsyncThunk(
        constants.FIND_MY_GHOSTING_COMPANIES,
        async () => {
          const response = await client.get(`/api/v2/my-ghosting`)
          return response.data
        }
      )
      dispatch(fetchMyGhostingCompanies())
    }
  },
  updateMyGhostingCompany: (company) => {
    return async dispatch => {
      const updateMyGhostingCompany = createAsyncThunk(
        constants.UPDATE_MY_GHOSTING_COMPANY,
        async () => {
          const response = await client.put(`/api/v2/my-ghosting`, company)
          return response.data
        }
      )
      dispatch(updateMyGhostingCompany())
    }
  }
})

export default api